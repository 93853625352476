.lc-outline-view .tree-node .slot-summary-wrapper {
  opacity: 0.6;
}
.lc-outline-view .tree-node .summary-wrapper {
  user-select: none;
  width: 100%;
  padding: 3px 8px;
}
.lc-outline-view .tree-node .summary-wrapper:hover {
  background-color: #F8F9F9;
  cursor: pointer;
}
.lc-outline-view .tree-node .summary-wrapper.context-visible {
  background-color: #EBF5FF;
}
.lc-outline-view .tree-node .summary-wrapper.active {
  background-color: #f3f9ff;
}
.lc-outline-view .tree-node .summary-wrapper.drop-target {
  background-color: #FEF5E7;
}
.lc-outline-view .tree-node .summary-wrapper.placeholder {
  background-color: #EAFAF1;
}
.lc-outline-view .tree-node .summary-wrapper.being-dragged {
  opacity: 0.4;
}
.lc-outline-view .tree-node .summary {
  display: flex;
}
.lc-outline-view .tree-node .summary .title {
  padding-left: 0.25rem;
  flex-grow: 1;
}
.lc-outline-view .tree-node .op-icon-box {
  width: 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
}
