.small-edit-form {
  position: relative;
  padding: 1rem 1.5rem 1.5rem 1rem;
}
.small-edit-form.single {
  padding-top: 2rem;
}
.small-edit-form .edit-box-actions {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  display: flex;
}
.small-edit-form .edit-box-actions .anticon {
  padding: 0.5rem;
  cursor: pointer;
}
.small-edit-form .field-item .item-label {
  font-size: 11px;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}
.small-edit-form .field-item:not(:last-child) {
  margin-bottom: 1rem;
}
.small-edit-form .edit-box-body {
  max-height: 20rem;
  overflow: auto;
}
