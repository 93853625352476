.newsMaterials-content {
  height: 35rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.newsMaterials-content > div {
  position: relative;
  height: 15rem;
  margin: 1.25rem;
  padding: 0.75rem;
  width: 18rem;
  border: 1px solid #e7e7eb;
  cursor: pointer;
}
.newsMaterials-content > div img {
  width: 100%;
  height: 10rem;
}
.newsMaterials-content > div .wxmp-news-title {
  padding: 1rem 1rem 0px;
}
.newsMaterials-content > div .wxmp-news-title p {
  height: 2.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.newsMaterials-content > div .selected {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 100px;
  color: #fff;
  background-color: #000;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
