.admin--excel-export .top-toolbar {
  padding: 0.5rem 0;
  justify-content: space-between;
  display: flex;
  width: 100%;
}
.admin--excel-export .item-list {
  padding: 0.5rem 0;
}
.admin--excel-export .item-list > :not(:last-child) {
  margin-bottom: 1rem;
}
