.preset-card-container {
  height: 100%;
}
.preset-card-container .ant-tabs-card {
  height: 100%;
}
.preset-card-container .search-wrapper {
  padding: 8px 16px;
}
.preset-card-container .ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0 0;
}
.preset-card-container .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  margin-left: 0!important;
  border: none;
  background-color: #f0f2f5;
  padding: 8px 16px;
}
.preset-card-container .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff;
}
.preset-card-container .ant-tabs-card .ant-tabs-content {
  height: calc(100% - 16px);
}
.preset-card-container .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
}
.preset-card-container .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}
.preset-card-container .ant-tabs-card > .ant-tabs-bar {
  border-bottom-width: 0;
  margin-bottom: 0;
}
.preset-card-container .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.preset-card-container .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.src-tabs {
  display: flex;
  height: 100%;
}
.src-tabs .tab-bar {
  overflow: auto;
  min-width: 260px;
  padding-top: 0.5rem;
}
.src-tabs .tab-bar .search-wrapper {
  padding-top: 0;
}
.src-tabs .tab-bar .tab-ctrl {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.src-tabs .tab-bar .tab-ctrl.active {
  color: #3E8ECD;
}
.src-tabs .tab-content {
  flex-grow: 1;
}
