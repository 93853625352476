.admin--input--richtext .rich-text {
  text-align: initial;
}
.admin--richtext-input {
  width: 100%;
  overflow: hidden;
  min-height: 200px;
  padding: 0.125rem 0;
  border-radius: 4px;
  border: 1px solid #e5e5ea;
  height: 100%;
}
.admin--richtext-input .rich-text {
  text-align: initial;
}
