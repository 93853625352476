.wxmp-yqylPromotion-container {
  max-height: 32.375rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
}
.wxmp-yqylPromotion-container > div:not(:last-child) {
  margin-bottom: 1rem;
}
.wxmp-yqylPromotion-container .yqyl-promotion {
  height: 13rem;
  padding: 1rem 1.25rem;
  border: 1px solid #e7e7eb;
  background-color: white;
  position: relative;
}
.wxmp-yqylPromotion-container .yqyl-promotion:hover .delete {
  display: block;
}
.wxmp-yqylPromotion-container .yqyl-promotion label {
  width: 4.5rem;
  margin-right: 0.5rem;
}
.wxmp-yqylPromotion-container .yqyl-promotion .ant-select {
  width: 15rem!important;
}
.wxmp-yqylPromotion-container .yqyl-promotion .delete {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}
