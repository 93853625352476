.bw-admin--code-search {
  margin: 0.5rem;
  padding: 1rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  border-radius: 2px;
  background-color: white;
}
.bw-admin--code-search .toolbar {
  display: flex;
  margin-bottom: 1rem;
}
.bw-admin--code-search .toolbar > :not(:last-child) {
  margin-right: 1rem;
}
.bw-admin--code-search .search-hits {
  display: flex;
  flex-direction: column;
}
.bw-admin--code-search .search-hits pre {
  background-color: #F0F3F5;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 11px;
}
.bw-admin--code-search .search-hits > pre:not(:last-child) {
  margin-bottom: 0.5rem;
}
