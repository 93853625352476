.lc-color-input {
  display: flex;
}
.lc-color-input:hover .remove-icon {
  display: flex;
}
.lc-color-input .remove-icon {
  padding: 0 0.25rem;
  opacity: 0.35;
  cursor: pointer;
  line-height: 10px;
  display: none;
  align-items: center;
  font-size: 13px;
}
.lc-color-input .color-content {
  height: 1.375rem;
  width: 5rem;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lc-color-input .color-content .color-value {
  visibility: visible;
  font-size: 11px;
}
