.lc-form-management {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
}
.lc-form-management .form-list-nav {
  height: 100%;
  min-width: 160px;
  width: 180px;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  border-right: 0.5px solid #e5e6eb;
  font-size: 12px;
}
.lc-form-management .form-list-nav .form-list-nav-tools {
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  display: flex;
  align-items: center;
}
.lc-form-management .form-list-nav .form-list-nav-tools .add-form-button {
  min-width: 27px;
  height: 27px;
  width: 27px;
  margin-left: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lc-form-management .form-list-nav .form-list-nav-tools .add-form-button:not(:hover) {
  color: inherit;
  border-color: inherit;
  border-width: 0;
  opacity: 0.65;
}
.lc-form-management .form-list-nav .arco-menu {
  font-size: 12px;
  width: 100%;
  flex-grow: 10000;
  overflow: auto;
}
.lc-form-management .form-list-nav .menu-icon {
  display: inline-flex;
  font-size: 14px;
  opacity: 0.8;
  align-items: center;
  transform: translateY(2.5px);
  margin-right: 8px;
}
