.edit-tags > :not(:last-child) {
  margin-right: 8px;
}
.edit-tags .ant-tag {
  vertical-align: top;
  height: 24px;
  line-height: 22px;
}
.edit-tags .text-auto-complete-box {
  width: 100px;
}
.edit-tags .text-auto-complete-box .ant-select-auto-complete .ant-select-selector {
  line-height: 24px;
  padding: 0;
  height: 26px;
}
.edit-tags .text-auto-complete-box .ant-select-auto-complete .ant-select-selector input {
  height: 24px;
}
.edit-tags .ant-btn.ant-btn-icon-only.ant-btn-sm {
  margin-top: 1px;
  height: 22px;
  opacity: 0.6;
}
