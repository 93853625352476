.lc-block-gallery {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0;
}
.lc-block-gallery .gallery-section .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: #646566;
  padding: 3px 0.5rem 2px 1.5rem;
  border-bottom: 0.5px solid #F0F3F5;
  border-top: 0.5px solid #F0F3F5;
  background-color: #fafafa;
}
.lc-block-gallery .gallery-section .section-title:hover {
  background-color: #F9FAFC;
  cursor: pointer;
}
.lc-block-gallery .gallery-section .section-title .op-icon {
  padding: 0.25rem;
  height: 23px;
}
.lc-block-gallery .block-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.lc-block-gallery .block-list.hidden {
  display: none;
}
.lc-block-gallery .block-list .block-picker {
  width: 88px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.lc-block-gallery .block-list .block-picker:hover,
.lc-block-gallery .block-list .block-picker:hover .icon {
  background-color: #F9FAFC;
  cursor: pointer;
}
.lc-block-gallery .block-list .block-picker .icon {
  font-size: 16px;
  color: #646566;
}
