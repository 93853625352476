.draggable-input.edit-num-input {
  display: flex;
  flex-wrap: wrap;
}
.draggable-input.edit-num-input .draggable-input-item {
  margin-right: 10px;
  margin-bottom: 6px;
}
.draggable-input.edit-num-input .draggable-input-item .base-input-col {
  width: 6rem;
}
.draggable-input.edit-num-input .draggable-input-item .base-input-col .num-input-control {
  width: 100%;
}
.draggable-input.edit-num-input .draggable-input-item .close-icon-col {
  margin-left: 5px;
}
.draggable-input.edit-num-input .add-input-btn {
  padding: 0 6px;
  max-width: 8.25rem;
  min-width: 1.5rem;
}
.num-input-control {
  min-width: 6rem;
}
