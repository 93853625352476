.studio-property-editor {
  padding: 3rem 1rem;
  height: 100%;
  overflow: auto;
}
.studio-property-editor .property-section {
  display: flex;
}
.studio-property-editor .property-section:not(:last-child) {
  margin-bottom: 1rem;
}
.studio-property-editor .property-section .property-name {
  width: 21%;
  max-width: 200px;
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
  padding-right: 16px;
}
.studio-property-editor .property-section .property-value {
  width: 70%;
  display: flex;
}
