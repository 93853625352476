.code-mirror-editor {
  height: 100%;
}
.code-mirror-editor .ͼ1 .cm-scroller {
  overflow: auto;
}
.code-mirror-editor .cm-editor {
  height: 100%;
  background-color: #fefcf8;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/
}
.code-mirror-editor .cm-editor .cm-selectionMatch {
  background-color: #F0F2F0;
}
.code-mirror-editor .cm-editor .cm-scroller {
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 11px;
  font-feature-settings: "liga" 0, "calt" 0;
  line-height: 17px;
  letter-spacing: 0px;
}
.code-mirror-editor .cm-editor.cm-focused {
  outline: none;
}
.code-mirror-editor .cm-editor .ͼ1 .cm-lintPoint {
  position: relative;
  margin-bottom: -6px;
  display: inline-block;
}
.code-mirror-editor .cm-editor .cm-gutters {
  background-color: #fefcf8;
  color: #C8CACC;
  border-right: none;
  width: 3.5rem;
}
.code-mirror-editor .cm-editor .cm-gutters .cm-lineNumbers {
  width: 75%;
  font-size: 11px;
}
.code-mirror-editor .cm-editor .cm-gutters .cm-foldGutter {
  width: 25%;
}
.code-mirror-editor .cm-editor .cm-gutters .cm-foldGutter span[title="Fold line"] {
  display: block;
  margin-top: -2px;
}
.code-mirror-editor .cm-editor .cm-gutters .cm-lint-marker {
  width: 6px;
  height: 6px;
  margin-top: -3px;
}
.code-mirror-editor .cm-editor ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.code-mirror-editor .cm-editor ::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgba(0, 0, 0, 0.01);
}
.code-mirror-editor .cm-editor ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.04);
}
