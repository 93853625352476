.toolbar-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 1.75rem;
}
.toolbar-button-wrapper .button-panel {
  z-index: 500;
  top: calc(100% + 0.5px);
  position: absolute;
  display: none;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.toolbar-button-wrapper .button-panel.visible {
  display: block;
}
.toolbar-button-wrapper .show-more-button {
  height: 100%;
  display: flex;
  align-items: center;
}
.toolbar-button-wrapper .show-more-button:hover {
  background-color: #F9FAFC;
  cursor: pointer;
}
.toolbar-button-wrapper .show-more-button.disabled {
  opacity: 0.4;
}
.toolbar-button-wrapper .show-more-button.disabled:hover {
  background-color: white;
  cursor: default;
}
.toolbar-button-wrapper .icon-button {
  padding: 0 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.toolbar-button-wrapper .icon-button.with-show-more-button {
  padding-right: 0.375rem;
}
.toolbar-button-wrapper .icon-button.pressable:hover {
  background-color: #F9FAFC;
  cursor: pointer;
}
.toolbar-button-wrapper .icon-button.disabled {
  opacity: 0.4;
}
.toolbar-button-wrapper .icon-button.disabled:hover {
  background-color: white;
  cursor: default;
}
.toolbar-button-wrapper .icon-button.active {
  color: #3498DB;
}
