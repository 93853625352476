.admin--field-form {
  display: flex;
}
.admin--field-form .field-group > :not(:last-child) {
  margin-bottom: 1rem;
}
.admin--field-form > :not(:first-child) {
  padding-left: 1rem;
}
.admin--field-form > div {
  padding-right: 1rem;
}
.admin--field-form:not(:last-child) {
  margin-right: 2rem;
}
.admin--field-form:last-child {
  margin-right: 0.25rem;
}
.admin--field-form.layout-horizontal.no-label .field-item .item-value {
  max-width: 100%;
}
.admin--field-form.layout-horizontal .field-item {
  display: flex;
}
.admin--field-form.layout-horizontal .field-item .item-label {
  text-align: right;
  max-width: 8rem;
  min-width: 3rem;
  padding-right: 16px;
}
.admin--field-form.layout-horizontal .field-item .label-wrapper {
  display: inline-flex;
  align-items: center;
}
.admin--field-form.layout-horizontal .field-item .item-value {
  flex-grow: 100;
}
.admin--field-form.layout-vertical {
  padding-left: 1rem;
}
.admin--field-form.layout-vertical .field-item .item-label {
  margin-bottom: 0.5rem;
}
.admin--field-form .field-item .item-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.admin--field-form .field-item .item-value {
  min-height: 18px;
  display: flex;
}
.admin--field-form .field-item .item-value .date-input-control {
  width: 100%;
}
