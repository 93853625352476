.lookup-value-edit {
  padding: 2rem!important;
}
.lookup-value-edit > :not(:last-child) {
  margin-bottom: 1.5rem;
}
.lookup-value-edit .category .category-title {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.5rem;
}
