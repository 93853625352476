.wxmp-reply-container {
  height: 100%;
  margin: 0 1rem;
  padding: 1rem;
  background-color: #fff;
  display: flex;
}
.wxmp-reply-container .ant-tabs {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wxmp-reply-container .ant-tabs .ant-tabs-content {
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
}
.wxmp-reply-container .ant-tabs .ant-tabs-content .ant-tabs-tabpane-active {
  height: 100%;
}
