.studio-root .dialog-head {
  padding: 0.5rem 1rem;
  font-weight: bold;
}
.studio-root .dialog-form {
  padding: 1rem;
}
.studio-root .dialog-form .form-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.studio-root .dialog-form .form-item .label {
  opacity: 0.8;
  margin-bottom: 0.25rem;
}
.studio-root .studio-dialog {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.studio-root .studio-dialog .dialog-head {
  margin: 0.25rem 0;
  padding: 0;
}
.studio-root .studio-dialog .dialog-section {
  display: flex;
  flex-direction: column;
}
.studio-root .studio-dialog .dialog-section .prompt-message {
  padding: 1rem 0 0.5rem;
}
.studio-root .studio-dialog .dialog-section:not(:last-child) {
  margin-bottom: 1.25rem;
}
.studio-root .studio-dialog .dialog-section > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.studio-root .dialog-button-bar,
.studio-root .bottom-button-bar {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 0.5rem 1rem;
}
.studio-root .dialog-button-bar button:not(:last-child),
.studio-root .bottom-button-bar button:not(:last-child) {
  margin-left: 0.5rem;
}
.studio-app {
  display: flex;
  flex-direction: column;
  background-color: #f5f7f9;
  height: 100%;
  width: 100%;
}
.studio-app .top-area {
  height: 32px;
  border-bottom: 1px solid #e9e9e9;
}
.studio-app .main-area-wrapper {
  height: calc(100% - 32px);
}
.studio-app .main-area-wrapper .main-area {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
  position: relative;
}
.studio-app .main-area-wrapper .main-area .empty-canvas {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20%;
  user-select: none;
}
.studio-app .main-area-wrapper .main-area .editor-tabs {
  width: 100%;
  height: 1.75rem;
  display: flex;
  overflow-x: overlay;
}
.studio-app .main-area-wrapper .main-area .editor-tabs::-webkit-scrollbar {
  height: 4px;
  border-radius: 2px;
}
.studio-app .main-area-wrapper .main-area .editor-tabs::-webkit-scrollbar-thumb,
.studio-app .main-area-wrapper .main-area .editor-tabs::-webkit-scrollbar-track {
  background-color: transparent;
}
.studio-app .main-area-wrapper .main-area .editor-tabs:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
}
.studio-app .main-area-wrapper .main-area .editor-tabs:hover::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.07);
}
.studio-app .main-area-wrapper .main-area .editor-tabs.highlighted {
  background-color: #EBF5FF;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .inactive + .inactive {
  border-left: 1px solid #e9e9e9;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  background-color: white;
  cursor: default;
  padding: 0 0.25rem 0 0.75rem;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.has-error {
  color: #D22B2B;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.inactive {
  background-color: #f0f2f3;
  cursor: pointer;
  color: #646566;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.inactive.has-error {
  color: #C41E3A;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.inactive .close-tab-btn:hover {
  background-color: #ecefef;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.inactive .close-tab-btn > svg {
  opacity: 0;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.inactive:hover .close-tab-btn > svg {
  opacity: 0.9;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .x-small-icon {
  width: 10px;
  height: 10px;
  min-width: 10px;
  font-size: 10px;
  margin-bottom: 1.75px;
  margin-right: 4px !important;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .dirty-mark {
  display: none;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #B4B6B8;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.dirty .dirty-mark {
  display: block;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav.dirty .close-tab-btn > svg {
  display: none;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav > :not(:last-child) {
  margin-right: 0.125rem;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .close-tab-btn {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 2px;
  font-size: 12px;
  color: #646566;
  display: flex;
  justify-content: center;
  align-items: center;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .close-tab-btn > svg {
  opacity: 0.9;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .close-tab-btn:hover {
  background-color: #f0f2f2;
  cursor: pointer;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .close-tab-btn:hover .dirty-mark {
  display: none;
}
.studio-app .main-area-wrapper .main-area .editor-tabs .editor-tab-nav .close-tab-btn:hover > svg {
  display: block;
}
.studio-app .main-area-wrapper .main-area .editor-wrapper {
  height: 100%;
  width: 100%;
  display: block;
}
.studio-app .main-area-wrapper .main-area .editor-wrapper.inactive {
  display: none;
}
.studio-app .user-interface-icon {
  color: #9B59B6;
}
.studio-app .process-icon {
  color: #F39C12;
}
.studio-app .page-icon {
  color: #9B59B6;
}
.studio-app .folder-icon {
  color: #F4D03F;
}
.studio-app .entity-icon {
  color: #3498DB;
}
.studio-app .field-icon {
  color: #3498DB;
}
.studio-app .virtual-field-icon,
.studio-app.backlink-field-icon {
  color: #F39C12;
}
.studio-app .data-interface-query-icon {
  color: #28B463;
}
.studio-app .data-interface-operation-icon {
  color: #E74C3C;
}
.studio-app .dependency-icon {
  color: #FFA000;
}
.studio-app .system-dependency-icon {
  color: #AEB6BF;
}
.studio-app .x-small-icon {
  width: 0.875rem;
  height: 0.875rem;
  min-width: 0.875rem;
  font-size: 0.875rem;
}
.studio-app .small-icon {
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  font-size: 1.25rem;
}
.studio-app .medium-icon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  font-size: 2rem;
  border-radius: 4px;
}
.studio-app .large-icon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  background-color: #e9e9e9;
  border-radius: 4px;
}
.studio-app .small-icon-button {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 2px;
  font-size: 12px;
  color: #646566;
  display: flex;
  justify-content: center;
  align-items: center;
}
.studio-app .small-icon-button:hover {
  background-color: #f0f2f2;
  cursor: pointer;
}
.metadata-panel {
  background-color: white;
  padding: 0.75rem;
  min-width: 200px;
  max-width: 480px;
  overflow-x: auto;
  max-height: 75vh;
  overflow: auto;
}
.metadata-panel .label-title {
  font-size: 11px;
}
.metadata-panel.detail :not(:last-child).metadata-row {
  margin-bottom: 0.25rem;
}
.metadata-panel.detail .label-title {
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.metadata-panel > :not(:last-child) {
  margin-bottom: 1rem;
}
.metadata-panel .metadata-row {
  display: flex;
  font-size: 11px;
}
.metadata-panel .metadata-row > :not(:last-child) {
  margin-right: 0.5rem;
}
.metadata-panel .metadata-row .label-cell {
  width: 9rem;
}
.metadata-panel .metadata-row .detail-cell {
  background-color: #F0F0F0;
  flex: 1;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: smaller;
}
