.crontab-samples {
  opacity: 0.85;
  padding: 0.5rem 0;
}
.crontab-samples > :not(:last-child) {
  margin-bottom: 0.25rem;
}
.crontab-samples.error {
  color: #FF4760;
}
