.page-design-frame {
  width: 100%;
  height: 100%;
  position: relative;
}
.page-design-frame .canvas-tool-bar {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 2rem;
  border-bottom: 0.5px solid #f0f0f0;
  padding: 0 0.5rem;
  background-color: white;
  justify-content: center;
  display: flex;
  z-index: 100;
}
.page-design-frame .canvas-tool-bar .input-box {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider {
  z-index: 100;
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-slider-bar {
  height: 3px;
  border-radius: 1.5px;
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-tooltip {
  transform: translateX(-2px);
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-slider-handle {
  top: -60%;
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-slider-handle:hover::before,
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-slider-handle:focus::before {
  box-shadow: none;
}
.page-design-frame .canvas-tool-bar .input-box .rs-slider .rs-slider-handle::before {
  width: 8px;
  height: 8px;
  border-color: #C8CACC;
}
.page-design-frame .canvas-tool-bar .input-box.scale-input {
  margin-left: 4px;
}
.page-design-frame .canvas-tool-bar .input-box.scale-input .rs-input-number {
  width: 56px;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-group:focus-within {
  box-shadow: none;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number {
  width: 64px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  border-bottom-color: #F7F7FA;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number .rs-input-number-btn-group-vertical {
  display: none;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number .rs-input-group-addon {
  padding: 0px 4px;
  font-size: 11px;
  background-color: #FAFCFE;
  min-width: auto;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number .rs-input {
  text-align: right;
  font-size: 11px;
  padding: 4px 6px 4px 8px;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number .rs-btn {
  padding: 0;
  font-size: 11px;
}
.page-design-frame .canvas-tool-bar .input-box .rs-input-number .rs-btn .rs-icon {
  height: 10px;
}
.page-design-frame .canvas-main {
  position: absolute;
  top: 2rem;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f7f9fA;
}
.page-design-frame .canvas-main .screen-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0 1rem;
}
.page-design-frame .canvas-main .canvas-screen {
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
  background-color: white;
  overflow: hidden;
}
.page-design-frame .canvas-main .canvas-frame {
  height: 100%;
  width: 100%;
  overflow: auto;
  outline: medium none;
  border: none;
  margin: auto;
  display: block;
  transition: width 0.35s ease, height 0.35s ease;
}
