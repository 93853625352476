.studio-item-action-menu {
  padding: 0.25rem 0.25rem;
  display: flex;
  flex-direction: column;
}
.studio-item-action-menu .action-menu-group {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
}
.studio-item-action-menu .action-menu-group .seperator {
  margin: 2px 0.5rem;
  border-bottom: 1px solid #e9e9e9;
}
.studio-item-action-menu .action-menu-group .action-menu-item {
  padding: 3px 0.5rem;
  border-radius: 6px;
  cursor: default;
  min-width: 200px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                          supported by Chrome, Opera and Firefox */
}
.studio-item-action-menu .action-menu-group .action-menu-item:hover {
  background-color: #47A9FF;
  color: white;
}
