.toolbar-content .toolbar-override {
  width: 300px;
}
.toolbar-content .toolbar-override input {
  outline: none;
  border: none;
}
.toolbar-content input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: 28px;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--lc-color-border);
  border-radius: 4px;
}
.toolbar-content .seperator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  margin: 8px 6px;
  height: 15px;
  background: var(--lc-color-border);
}
.editor-button {
  font-size: 12px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border: 0px solid transparent;
  height: 30px;
  outline: none;
  background-color: inherit;
  line-height: 15px;
}
.editor-button svg {
  height: 18px;
}
.editor-button.active {
  color: var(--blue11);
}
@media (min-width: 640px) {
  .editor-button:hover {
    background-color: var(--gray3);
    cursor: pointer;
  }
}
.editor-button:hover:disabled {
  background-color: unset;
}
.editor-button:disabled {
  color: var(--gray5);
}
.editor-button-group {
  display: flex;
}
.editor-toolbar .editor-button-group {
  flex-wrap: wrap;
}
.inline-toolbar {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid var(--lc-color-border);
  background: var(--lc-color-bg-2);
  border-radius: 3px;
  padding: 2px 0;
  box-shadow: 0px 1px 2px 0px var(--lc-color-border);
  box-sizing: border-box;
}
.inline-toolbar:not(.no-arrow):after,
.inline-toolbar:not(.no-arrow):before {
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.inline-toolbar.up::after {
  display: none;
}
.inline-toolbar.bottom::before {
  display: none;
}
.inline-toolbar:not(.no-arrow):after {
  top: 100%;
  border-color: transparent;
  border-top-color: var(--lc-color-bg-2);
  border-width: 4px;
  margin-left: -4px;
}
.inline-toolbar:not(.no-arrow):before {
  bottom: 100%;
  border-color: transparent;
  border-bottom-color: var(--lc-color-bg-2);
  border-width: 4px;
  margin-left: -4px;
}
.simple-editor .DraftEditor-root {
  padding: 0 8px;
}
.editor.brushing {
  cursor: crosshair;
}
.editor-box {
  position: relative;
}
.editor {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
}
.editor .DraftEditor-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: inherit;
}
.editor .DraftEditor-root .public-DraftEditorPlaceholder-root {
  position: absolute;
  opacity: 0.6;
}
.editor .DraftEditor-root .public-DraftEditorPlaceholder-root.public-DraftEditorPlaceholder-hasFocus {
  opacity: 0.4;
}
.editor .DraftEditor-root .DraftEditor-editorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: inherit;
}
.editor .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
  flex: 1;
  min-height: inherit;
}
.editor .public-DraftStyleDefault-ltr {
  text-align: inherit;
}
.editor .public-DraftStyleDefault-rtl {
  text-align: inherit;
}
.editor .editor-toolbar {
  margin: 0 0;
  padding: 0 8px;
}
.editor .cb-text-align-left {
  text-align: left;
}
.editor .cb-text-align-right {
  text-align: right;
}
.editor .cb-text-align-justify {
  text-align: justify;
}
.editor .cb-text-align-center {
  text-align: center;
}
.editor .cb-dropdown-wrapper {
  z-index: 100;
  height: 30px;
  width: 56px;
}
.editor .cb-dropdown-wrapper i.cb-dropdown-indicator {
  position: absolute;
  top: 10px;
  font-size: 9px;
  margin: 0 0 0 4px;
}
.editor .cb-dropdown-optionwrapper {
  font-size: 12px;
  width: 90px;
  border: 1px solid #F1F1F1;
  background-color: #FFF;
}
.editor .cb-dropdown-optionwrapper li {
  padding: 4px 8px;
  width: 90px;
  cursor: pointer;
  margin-left: 0;
  list-style: none;
}
.editor .cb-dropdown-optionwrapper li:hover {
  background-color: #e6e6e6;
}
.editor .cb-dropdown-button {
  position: relative;
}
.editor .cb-dropdown-button:hover {
  background-color: #e6e6e6;
}
.editor .editor-image-block {
  padding: 2px 0;
  width: 50%;
  z-index: 100;
  position: relative;
}
.editor .editor-image-block.uploading img {
  opacity: 0.5;
}
.editor .editor-image-block .editor-image-toolbar {
  display: none;
  margin: 0 auto -38px auto;
  padding: 4px 0;
  width: 112px;
  opacity: 0.7;
}
.editor .editor-atomic-block.focused {
  border-color: #1aaa55;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #6CA9D9;
}
.editor .editor-atomic-block:hover:not(.focused) {
  border-color: #1aaa55;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #BDD8EE;
}
.editor .editor-audio-block {
  padding: 0.75rem;
}
.editor .editor-video-block {
  background-color: #F0F6FB;
}
.editor .editor-video-block .editor-video-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor .editor-video-block .editor-video-input input {
  outline: none;
  border: none;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0);
}
.editor .editor-video-block .editor-video-block-scarf {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.editor .editor-video-block .editor-video-block-tool {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 3;
}
.editor .editor-video-block .editor-video-block-tool button {
  opacity: 0.5;
}
.editor .editor-video-block .editor-video-block-tool button:hover {
  opacity: 1;
}
.editor .editor-video-block .editor-video-block-tool .seperator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
}
.editor .editor-video-block .editor-video-block-tool .fa {
  width: 100%;
  height: 100%;
}
