.avatar-and-label {
  display: flex;
  align-items: center;
}
.avatar-and-label:not(:last-child) {
  margin-right: 0.5rem;
}
.avatar-and-label a {
  display: flex;
  align-items: center;
}
.avatar-and-label .span {
  display: inline-block;
  max-width: 100px;
  vertical-align: middle;
}
