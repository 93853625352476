.add-dependency-dialog .app-gallery {
  display: flex;
  flex-wrap: wrap;
  min-height: 8rem;
}
.add-dependency-dialog .app-gallery > :not(:last-child) {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.add-dependency-dialog .app-box {
  height: 6rem;
  width: 8rem;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-dependency-dialog .app-box:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
