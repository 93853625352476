.fluid-text {
  font-family: Regular, PingFang-Regular, 'Microsoft YaHei', sans-serif;
}
.fluid-text.fluid-text-quote {
  font-family: Light, PingFang-Light, 'Microsoft YaHei', sans-serif;
}
.fluid-text.fluid-text-display {
  font-family: SemiBold, PingFang-Semibold, 'Microsoft YaHei', sans-serif;
}
@media (min-width: 640px) {
  .fluid-text {
    font-size: 1rem;
  }
  .fluid-text.fluid-text-helper {
    font-size: 0.875rem;
  }
  .fluid-text.fluid-text-quote {
    font-size: 1rem;
    font-weight: 300;
  }
  .fluid-text.fluid-text-display {
    font-size: 3.125rem;
    font-weight: 600;
    letter-spacing: 0;
  }
  .fluid-text.fluid-text-big {
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: 0;
  }
}
@media (max-width: 639px) {
  .fluid-text {
    font-size: 0.875rem;
  }
  .fluid-text.fluid-text-helper {
    font-size: 0.75rem;
  }
  .fluid-text.fluid-text-quote {
    font-size: 0.875rem;
    font-weight: 300;
  }
  .fluid-text.fluid-text-display {
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: 0;
  }
  .fluid-text.fluid-text-big {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
  }
}
