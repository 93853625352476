.admin--entity-card {
  display: flex;
  flex-direction: column;
}
.admin--entity-card.ant-card {
  border-radius: 4px;
}
.admin--entity-card.ant-card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.admin--entity-card.ant-card-bordered .ant-card-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.admin--entity-card.ant-card > .ant-card-body {
  padding: 0 !important;
}
.admin--entity-card.ant-card > .ant-card-head {
  min-height: 0;
  padding: 0 12px;
}
.admin--entity-card.ant-card .ant-card-head-wrapper .ant-card-head-title {
  padding: 8px 0;
  font-size: 13px;
  font-weight: 500;
}
.admin--entity-card.ant-card .ant-card-head-wrapper .ant-card-head-title .title-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin--entity-card.ant-card .ant-card-head-wrapper .ant-card-head-title .title-line span {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}
.admin--entity-card .card-body {
  padding: 8px 12px;
  flex-grow: 1;
  height: 216px;
  overflow: auto;
}
.admin--entity-card .card-body .info-list > :not(:last-child) {
  margin-bottom: 8px;
}
.admin--entity-card .card-body .caption {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
}
.admin--entity-card .card-body .info-line {
  display: flex;
  justify-content: space-between;
}
.admin--entity-card .card-body .info-name {
  width: 55%;
  min-width: 55%;
  font-size: 11px;
}
.admin--entity-card .card-body .info-name.long {
  width: 80%;
}
.admin--entity-card .card-body .info-type {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  line-break: anywhere;
  text-align: right;
}
.admin--entity-card .card-footer {
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  opacity: 0.4;
}
.admin--entity-card .card-footer :not(:last-child) {
  margin-bottom: 0.5rem;
}
