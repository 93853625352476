.flex-child-container .sizing-options {
  align-items: center;
  display: grid;
  gap: 4px 8px;
  grid-column: 1 / -1;
  grid-template-columns: 54px 1fr 1fr 1fr;
}
.flex-child-container .sizing-options .options {
  grid-column-end: span 3;
}
.flex-child-container .sizing-options .customize-size-container {
  align-items: center;
  display: grid;
  gap: 0px 8px;
  grid-column: 1 / -1;
  grid-template-columns: 54px 1fr 1fr 1fr;
  padding: 0px;
}
.flex-child-container .sizing-options .customize-size-container .input:first-of-type {
  grid-column: 2 / auto;
}
.flex-child-container .sizing-options .customize-size-container .input:nth-child(5) .arco-input-inner-wrapper {
  padding-left: 4px!important;
  padding-right: 0px!important;
}
.flex-child-container .sizing-options .customize-size-container .label {
  justify-self: center;
  grid-row-start: 3;
}
.flex-child-container .sizing-options .customize-size-container .label:nth-child(2) {
  grid-column-start: 2;
}
.flex-child-container .sizing-options .customize-size-container .label:nth-child(4) {
  grid-column-start: 3;
}
.flex-child-container .sizing-options .customize-size-container .label:nth-child(6) {
  grid-column-start: 4;
}
.flex-child-container .alignment-edit-toggle {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0.25rem 0;
  align-items: center;
  background-color: #F0F3F5;
  cursor: pointer;
  font-size: 10px;
}
.flex-child-container .alignment-edit-toggle:hover {
  background-color: #f7f7f7;
}
.flex-child-container .alignment-edit-toggle > svg {
  margin-right: 0.25rem;
}
.flex-child-container .alignment-edit {
  margin-top: 0.5rem;
}
.flex-child-container .alignment-edit .customize-order-container {
  align-items: center;
  display: grid;
  gap: 4px 8px;
  grid-column: 1 / -1;
  grid-template-columns: 54px 1fr 1fr 1fr;
}
.flex-child-container .alignment-edit .customize-order-container .input {
  grid-column: 4 / auto;
}
.flex-child-container .alignment-edit .customize-order-container .label {
  justify-self: center;
  grid-column-start: 4;
}
