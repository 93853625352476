.size-container {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 54px 1fr 44px 1fr;
}
.size-container .overflow-options {
  grid-column: 1 / -1;
}
.size-container .fit-container {
  display: grid;
  grid-column: 1 / -1;
  align-items: center;
  gap: 8px;
  grid-template-columns: 54px 1fr;
}
.size-container .fit-container .fit-edit {
  display: flex;
}
.size-container .fit-container .fit-edit .edit-objectPosition-toggle {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #F0F3F5;
  padding: 0 0.5rem;
  color: #8c8d8f;
}
.size-container .fit-container .fit-edit .edit-objectPosition-toggle.active {
  background-color: #4297da;
  color: white;
}
.size-container .fit-container .fit-edit .edit-objectPosition-toggle > div {
  display: flex;
}
.size-container .arco-input-inner-wrapper {
  padding-left: 8px!important;
  padding-right: 0!important;
}
.objectPosition-edit {
  align-items: center;
  display: grid;
  gap: 8px 4px;
  grid-template-columns: 54px 1fr;
  padding: 8px;
}
