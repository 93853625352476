.defs-code-editor {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}
.defs-code-editor .float-button {
  background-color: white;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.defs-code-editor .code-mirror-editor {
  height: 100%;
  flex-grow: 100;
  width: 80%;
}
.defs-code-editor .code-mirror-editor .ͼ1 .cm-scroller {
  padding-bottom: 16rem;
}
.defs-code-editor .code-outline {
  width: 20%;
}
.defs-code-editor .syntax-tree-panel {
  width: 400px;
  min-width: 400px;
  height: 100%;
  padding: 1rem;
  border-left: 1px solid #F0F0F0;
  overflow: auto;
  font-size: 11px;
}
