.wxmp-materials .materials-box {
  display: flex;
  min-height: 13rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e7eb;
  background-color: white;
  padding: 0 2.25rem 0 6.65rem;
  position: relative;
}
.wxmp-materials .materials-box:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.wxmp-materials .materials-box:hover .delete {
  display: block;
}
.wxmp-materials .materials-box .wxmp-select,
.wxmp-materials .materials-box .wxmp-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.wxmp-materials .materials-box .wxmp-select .anticon,
.wxmp-materials .materials-box .wxmp-upload .anticon {
  font-size: 2rem;
}
.wxmp-materials .materials-box .wxmp-select input,
.wxmp-materials .materials-box .wxmp-upload input {
  display: none;
}
.wxmp-materials .materials-box label {
  width: 4.5rem;
  position: absolute;
  left: 1.25rem;
}
.wxmp-materials .materials-box .wxmp-news {
  display: flex;
  justify-content: center;
  position: relative;
}
.wxmp-materials .materials-box .wxmp-news .delete-news {
  color: #576b95;
  position: absolute;
  bottom: 1rem;
  right: -1.25rem;
  cursor: pointer;
}
.wxmp-materials .materials-box .wxmp-news > div:first-child {
  width: 18rem;
  float: left;
  cursor: pointer;
  margin: 1.25rem;
  padding: 0.75rem 0.875rem;
  border: 1px solid #e7e7eb;
}
.wxmp-materials .materials-box .wxmp-news > div:first-child div {
  width: 100%;
  height: 10.25rem;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.wxmp-materials .materials-box .wxmp-news > div:first-child p {
  margin-top: 1rem;
  max-height: 2.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.wxmp-materials .materials-box .show-image {
  height: 100%;
  position: relative;
  min-height: 13rem;
  display: flex;
  align-items: center;
}
.wxmp-materials .materials-box .show-image img {
  max-width: 100%;
  min-width: 8rem;
  max-height: 18.75rem;
  margin-left: auto;
}
.wxmp-materials .materials-box .show-image .swap {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  position: absolute;
  top: 5.8%;
  right: 4%;
  cursor: pointer;
}
.wxmp-materials .materials-box .delete {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}
