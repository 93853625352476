.admin--edit-modal .ant-modal-header {
  border-bottom: 1px solid #f6f6f4;
  border-radius: 6px 6px 0 0;
}
.admin--edit-modal .ant-modal-footer {
  border-top: 1px solid #f6f6f4;
}
.admin--edit-modal .ant-modal-content {
  border-radius: 6px;
}
