.form-gallery .section-title {
  display: flex;
  align-items: center;
  color: #646566;
  padding: 0.25rem 0.5rem;
  user-select: none;
  border-top: 0.5px solid #F0F3F5;
  background-color: #F0F3F5;
  justify-content: space-between;
}
.form-gallery .section-title > div:first-child {
  display: flex;
  align-items: center;
}
.form-gallery .section-title > div:first-child .op-icon {
  padding: 0.25rem 0;
  height: 23px;
  margin-right: 4px;
}
.form-gallery .section-title > div:first-child .active-circle {
  width: 4px;
  height: 4px;
  background-color: #4297da;
  border-radius: 50%;
  margin-left: 4px;
}
.form-gallery .styles-container {
  padding: 0.5rem 0.75rem 0.75rem;
  user-select: none;
}
