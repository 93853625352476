.admin--tab-bar {
  display: flex;
  padding: 0px 0rem;
}
.admin--tab-bar .tab-item {
  color: #3c3d3d;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                      supported by Chrome, Opera and Firefox */
  cursor: pointer;
  padding: 2px 1rem;
  opacity: 0.5;
  min-width: 4rem;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.admin--tab-bar .tab-item.active {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #1A73E9;
  cursor: default;
  opacity: 1;
  color: #1A73E9;
}
