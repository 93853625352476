.node-property-edit {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.node-property-edit > :not(:last-child) {
  border-bottom: 0.5px #F0F0F0 solid;
}
.node-property-edit .property-group {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.node-property-edit .property-group .title {
  font-size: 11px;
  padding: 0.5rem;
  background-color: #FAFAFA;
  margin-top: -1rem;
}
.node-property-edit .property-group > :not(:last-child) {
  margin-bottom: 0.75rem;
}
.node-property-edit .property-group .property-line {
  display: flex;
  width: 100%;
  min-width: 15rem;
}
.node-property-edit .property-group .property-line .label {
  width: 5rem;
  font-size: 11px;
  opacity: 0.7;
  text-align: right;
  padding-right: 1rem;
}
