.media-input-cot {
  padding: 10px;
}
.media-input-cot .media-upload-check {
  margin-top: 20px;
}
.media-input-cot .media-upload-cot {
  margin-top: 20px;
}
.media-input-cot .media-upload-cot .media-upload-progress {
  margin-top: 20px;
  background-color: #f7f7f7;
  padding: 10px;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-info {
  display: flex;
  justify-content: space-between;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-info a {
  font-size: 14px;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-info .cancel-upload-tip {
  color: #666;
  font-size: 12px;
  margin-left: 10px;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-progress-rate {
  font-weight: 400;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-progress-bar {
  margin-top: 15px;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-progress-bar .upload-progress-bar-total {
  width: 100%;
  height: 8px;
  background-color: #FFF;
  border-radius: 4px;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-progress-bar .upload-progress-bar-loaded {
  height: 8px;
  border-radius: 4px;
  background-color: #3e8ecd;
}
.media-input-cot .media-upload-cot .media-upload-progress .media-upload-progress-tip {
  margin-top: 10px;
}
