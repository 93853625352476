.flex-attributes-container {
  margin-top: 0.5rem;
}
.flex-attributes-container .directions {
  column-gap: 4px;
  grid-template-columns: 1fr 24px;
}
.flex-attributes-container .directions .directions-options {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 1px;
  border-radius: 2px;
}
.flex-attributes-container .gap-container {
  align-items: baseline;
  display: grid;
  gap: 8px;
  grid-template-columns: 54px 1fr;
  margin-bottom: 0.75rem;
}
.flex-attributes-container .gap-container .gap-input {
  display: grid;
  gap: 4px;
  grid-column: 2 / auto;
  grid-template-columns: 1fr 24px 1fr;
}
.flex-attributes-container .gap-container .gap-input .input .arco-input-inner-wrapper {
  padding-left: 8px;
  padding-right: 0;
}
.flex-attributes-container .gap-container .gap-input .gap-lock {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #8c8d8f;
}
.flex-attributes-container .gap-container .gap-input .gap-label {
  display: inline;
  justify-self: center;
  font-size: 10px;
}
.flex-attributes-container .gap-container .gap-input .gap-label:last-child {
  grid-column-start: 3;
}
