.lc-design-page {
  width: 100%;
  height: 100%;
}
.lc-design-placer {
  background-color: #00D084;
  position: absolute;
  pointer-events: none;
}
.lc-design-tools .hover-tools {
  position: absolute;
  outline: 1px solid #3b97e3;
  outline-offset: -1px;
  pointer-events: none;
}
.lc-design-tools .active-tools {
  position: absolute;
  outline: 2px solid #3b97e3;
  outline-offset: -2px;
  pointer-events: none;
}
.lc-design-tools .dropdown-wrapper {
  position: relative;
  display: flex;
}
.lc-design-tools .dropdown-wrapper:hover .dropdown-container {
  display: block;
}
.lc-design-tools .dropdown-wrapper .dropdown-trigger-button,
.lc-design-tools .dropdown-wrapper .dropdown-button {
  padding: 0 0.5rem;
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
  cursor: pointer;
}
.lc-design-tools .dropdown-wrapper .dropdown-container {
  position: absolute;
  display: none;
  top: 22px;
  padding-top: 2px;
}
.lc-design-tools .dropdown-wrapper .dropdown-container .dropdown-button {
  background-color: #8C8D8F;
  white-space: nowrap;
  border-radius: 2px;
}
.lc-design-tools .dropdown-wrapper .dropdown-container .dropdown-button:hover {
  background-color: #B4B6B8;
}
.lc-design-tools .dropdown-wrapper .dropdown-container .dropdown-button:not(:last-child) {
  margin-bottom: 2px;
}
