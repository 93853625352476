.bwax-table {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  background-color: #FFF;
  position: relative;
}
.bwax-table::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.bwax-table table {
  width: 100%;
}
.bwax-table tbody > tr > td,
.bwax-table thead > tr > th {
  min-height: 44px;
  padding: 0.625rem;
  word-wrap: break-word;
  overflow: hidden;
}
.bwax-table tbody > tr > td:first-child,
.bwax-table thead > tr > th:first-child {
  padding-left: 1rem;
}
.bwax-table thead > tr > th {
  border-bottom: 1px solid #f4f4f4;
}
.bwax-table thead > tr > th .clamped-text {
  opacity: 0.8;
}
.bwax-table tbody > tr > td {
  border-bottom: 1px solid #fafafa;
}
.bwax-table tbody > tr > td {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bwax-table thead > tr > th {
  display: flex;
  align-items: center;
  position: relative;
}
.bwax-table thead > tr > th .th-resizebar {
  height: 100%;
  width: 4px;
  position: absolute;
  right: 0;
  background: #DCDEE0;
}
.bwax-table .table-header-cot {
  position: relative;
}
.bwax-table .table-header {
  background: #fafafa;
  overflow-x: scroll;
}
.bwax-table .table-fixed-left,
.bwax-table .table-fixed-right {
  position: absolute;
  top: 0;
}
.bwax-table .table-fixed-left {
  left: 0;
}
.bwax-table .fixed-table-header {
  background: #fafafa;
}
.bwax-table .fixed-table-list {
  background: #fff;
}
.bwax-table .box-s {
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.bwax-table .table-header,
.bwax-table .fixed-table-list,
.bwax-table .fixed-items {
  scrollbar-width: none;
  /*Firefox*/
  -ms-overflow-style: none;
  /*IE 10+*/
}
.bwax-table .table-header::-webkit-scrollbar,
.bwax-table .fixed-table-list::-webkit-scrollbar,
.bwax-table .fixed-items::-webkit-scrollbar {
  display: none;
  /*Chrome Safari*/
}
.bwax-table .table-sorter {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}
.bwax-table .table-sorter:hover {
  cursor: pointer;
}
.bwax-table .table-sorter .anticon-caret-up,
.bwax-table .table-sorter .anticon-caret-down {
  padding: 0.25rem;
  font-size: 0.6rem;
  color: #bfbfbf;
}
.bwax-table .table-sorter .anticon-caret-up {
  padding-bottom: 0;
}
.bwax-table .table-sorter .anticon-caret-down {
  padding-top: 0;
}
.bwax-table .table-list-cot {
  flex: 1;
  overflow: hidden;
}
.bwax-table .table-actions {
  padding: 0.75rem;
}
.bwax-table .table-footer {
  padding: 0.75rem 1rem;
  border-top-width: 0;
}
.bwax-table .default-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bwax-table .table-list-empty {
  display: flex;
  flex-direction: column;
  flex-basis: 15rem;
  height: 15rem;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}
.bwax-table .table-list-empty .table-list-empty-desc {
  color: #666;
  line-height: 1.25rem;
}
