.entity-record-util-tabs {
  position: relative;
}
.entity-record-util-tabs .menu-bar {
  font-size: 1rem;
  height: 100%;
}
.entity-record-util-tabs .menu-bar .menu-item-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.entity-record-util-tabs .menu-bar .collapse-item {
  display: none;
}
.entity-record-util-tabs .menu-bar .menu-item,
.entity-record-util-tabs .menu-bar .collapse-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
.entity-record-util-tabs .menu-bar .menu-item:hover,
.entity-record-util-tabs .menu-bar .collapse-item:hover,
.entity-record-util-tabs .menu-bar .menu-item.active,
.entity-record-util-tabs .menu-bar .collapse-item.active {
  color: #3e8ecd;
}
.entity-record-util-tabs .menu-bar .menu-item.active,
.entity-record-util-tabs .menu-bar .collapse-item.active {
  background-color: rgba(0, 0, 0, 0.03);
}
.entity-record-util-tabs .menu-bar .menu-item.active,
.entity-record-util-tabs .menu-bar .collapse-item.active {
  cursor: default;
}
.entity-record-util-tabs .menu-bar .menu-item:active,
.entity-record-util-tabs .menu-bar .collapse-item:active {
  background-color: rgba(0, 0, 0, 0.06);
}
.entity-record-util-tabs .content-pane {
  display: none;
}
.entity-record-util-tabs.expanded {
  height: 100%;
  width: 18rem;
  max-width: 100%;
}
.entity-record-util-tabs.expanded .menu-bar {
  width: 100%;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  border-bottom: 1px solid #F0F3F5;
}
.entity-record-util-tabs.expanded .menu-bar .menu-item-list {
  flex-direction: row;
  overflow-x: hidden;
}
.entity-record-util-tabs.expanded .menu-bar .menu-item,
.entity-record-util-tabs.expanded .menu-bar .collapse-item {
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.entity-record-util-tabs.expanded .menu-bar .collapse-item {
  display: flex;
  align-items: center;
}
.entity-record-util-tabs.expanded .content-pane {
  display: flex;
  height: 100%;
  padding-top: 2.5rem;
}
.entity-record-util-tabs.expanded .content-pane .tab-content {
  width: 100%;
  display: none;
}
.entity-record-util-tabs.expanded .content-pane .tab-content.active {
  display: flex;
}
