.admin--page-content {
  padding: 0.25rem 1rem 0;
  width: 100%;
  height: 100%;
  min-width: 400px;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}
.admin--page-content.no-padding .page-content-body {
  padding: 0;
}
.admin--page-content.no-background .page-content-body {
  background: none;
}
.admin--page-content .page-content-body {
  overflow: auto;
  flex: 1;
  background: #fff;
  padding: 0.75rem 0.75rem;
  width: 100%;
  margin: 0;
  min-height: 280;
  display: flex;
  flex-direction: column;
}
