.lc-form-data {
  display: flex;
  flex-direction: column;
}
.lc-form-data > :not(:last-child) {
  margin-bottom: 1rem;
}
.lc-form-data .field-item {
  position: relative;
}
.lc-form-data .field-item .item-label {
  margin-bottom: 0.25rem;
}
.lc-form-data .field-item .item-label.item-require::before {
  content: "*";
  position: absolute;
  left: -8px;
  color: #f5222d;
}
.lc-form-data .ant-input-number {
  width: 100%;
}
