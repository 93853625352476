.lc-preview-setting-button {
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  transform: translate(var(--offset-x), var(--offset-y));
  z-index: 1000;
  background-color: white;
  user-select: none;
  font-size: 12px;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.lc-preview-setting-button.dragging {
  cursor: grabbing;
}
.lc-preview-setting-button .test-user {
  cursor: pointer;
}
.lc-preview-setting-button > :not(:last-child) {
  margin-right: 0.25rem;
}
.lc-preview-setting-button .login-button {
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
}
.lc-preview-setting-button .login-button:hover {
  background-color: #F0F0F0;
}
.lc-preview-setting-button:hover {
  opacity: 1;
}
.lc-preview-setting-login-popover {
  padding-right: 0.25rem;
  display: flex;
  justify-content: center;
}
.lc-preview-setting-login-popover .qr-code-tips {
  display: flex;
  justify-content: center;
  margin: 0.5rem auto;
  font-size: 12px;
}
