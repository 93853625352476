.code-outline {
  height: 100%;
  overflow: auto;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 11px;
  font-feature-settings: "liga" 0, "calt" 0;
  background-color: #fefcf8;
}
.code-outline > .children-list {
  padding: 0.5rem 0.25rem;
}
.code-outline .syntax-node-summary {
  display: flex;
  font-size: 10px;
  color: #626567;
  user-select: none;
  width: 100%;
}
.code-outline .syntax-node-summary.typedef,
.code-outline .syntax-node-summary.typealiasdef {
  color: #0288D1;
}
.code-outline .syntax-node-summary.functiondef {
  color: #117A65;
}
.code-outline .syntax-node-summary .op-icon {
  padding-top: 2px;
  width: 16px;
  min-width: 16px;
  display: flex;
  justify-content: center;
  color: #B4B6B8;
}
