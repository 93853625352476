.app-menu {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
}
.ant-menu-dark span {
  color: rgba(255, 255, 255, 0.87);
}
.ant-menu-dark .ant-menu-submenu-inline > .ant-menu-submenu-title:after {
  color: rgba(255, 255, 255, 0.87);
}
.design-app .cb-app-sider.ant-layout-sider {
  background: #425A7A;
}
.design-app .cb-app-sider .ant-badge-count {
  box-shadow: none;
}
.design-app .cb-app-sider .ant-badge-count p {
  color: #fff;
}
.design-app .trigger-box {
  color: #FFF;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 35px;
  box-shadow: 0 -2px 4px 0px rgba(0, 0, 0, 0.5);
  background-color: #384F6C;
}
.design-app .app-menu {
  padding-top: 4px;
}
.design-app .app-menu.ant-menu-dark {
  background: #425A7A;
}
.design-app .app-menu .ant-menu-item {
  height: 36px;
  line-height: 36px;
  font-size: 11px;
}
.design-app .app-menu .ant-menu-submenu .ant-menu-submenu-title {
  height: 36px;
  line-height: 36px;
  font-size: 11px;
}
.design-app .app-menu .ant-menu-submenu .ant-menu-sub {
  background: #384F6C !important;
  box-shadow: none!important;
}
