.bw-select.small .indicator {
  padding: 4px 2px;
}
.bw-select.small .indicator:last-child {
  margin-right: 4px;
}
.bw-select.small .bw-select__control {
  min-height: 26px;
}
.bw-select.small .bw-select__value-container {
  padding: 0 2px 0 6px;
}
.bw-select .indicator {
  color: rgba(0, 0, 0, 0.25);
  padding: 6px 5px;
  cursor: pointer;
}
.bw-select .indicator:last-child {
  margin-right: 5px;
}
.bw-admin .bw-select__menu {
  margin: 0;
}
.bw-admin .bw-select__control {
  box-shadow: none;
  min-height: 32px;
  border: 1px solid #e5e5ea;
}
.bw-admin .bw-select__control:hover {
  border-color: #3e8ecd;
}
.bw-admin .bw-select__control.bw-select__control--is-focused,
.bw-admin .bw-select__control.bw-select__control--menu-is-open {
  border-color: #3e8ecd;
}
.bw-admin .bw-select__option--is-selected,
.bw-admin .bw-select__option--is-selected.bw-select__option--is-focused {
  background-color: #f2faff;
  color: #3e8ecd;
}
.bw-admin .bw-select__option--is-focused {
  background-color: #f7f7fa;
  color: #3e8ecd;
}
