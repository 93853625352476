.admin--table-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.admin--table-view .topbar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 1rem 0;
}
.admin--table-view .topbar .top-left-bar > button,
.admin--table-view .topbar .top-left-bar > div {
  margin-right: 0.5rem;
}
.admin--table-view .topbar .top-right-bar > button {
  margin-left: 1rem;
}
.admin--table-view .topbar .top-right-bar > div {
  margin-left: 0.5rem;
}
.admin--table-view .topbar .top-left-bar,
.admin--table-view .topbar .top-right-bar {
  display: flex;
  align-items: center;
}
.admin--table-view .table-button-box {
  display: flex;
  padding: 0 0 0 0.75rem;
  align-items: center;
  justify-content: center;
}
.admin--table-view .table-value {
  display: flex;
  flex-wrap: wrap;
}
.admin--table-view .table-value > :not(:last-child) {
  margin-right: 1rem;
}
.admin--table-view .initializing-body-container {
  position: relative;
}
.admin--table-view .initializing-body-container .initializing-fixed-right-body {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -4px 0px 4px -4px rgba(0, 0, 0, 0.25);
}
.table-operations {
  padding: 0;
}
.table-operations .ant-popover-content .ant-popover-arrow {
  transform: rotate(45deg) translateX(6px) translateY(-8px);
}
.table-operations .ant-popover-content .ant-popover-inner-content {
  padding: 0;
}
.table-operations *:focus {
  outline: 0;
}
.table-operations .moreButtonMenu {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}
.table-operations .moreButtonMenu > div:not(:last-child) {
  border-bottom: 1px #F5F5F5 solid;
}
.admin-table-td-popover {
  min-width: 14rem;
  max-width: 30rem;
}
.admin-table-td-popover .small-field-edit-box {
  position: relative;
  padding: 2rem 2rem 1rem 1rem;
}
.admin-table-td-popover .small-field-edit-box .edit-box-actions {
  position: absolute;
  top: 0.25rem;
  right: 0.125rem;
}
.admin-table-td-popover .small-field-edit-box .edit-box-actions .anticon {
  padding: 0.5rem;
  cursor: pointer;
}
.admin-table-td-popover .small-field-edit-box .edit-box-actions .confirm-button {
  opacity: 1;
  color: #39CC87;
}
.admin-table-td-popover .small-field-edit-box .edit-box-actions .confirm-button.disabled {
  opacity: 0.3;
}
.admin-table-td-popover .small-field-edit-box .edit-box-body {
  min-height: 4rem;
  max-height: 20rem;
}
.admin-table-td-popover .small-field-edit-box .edit-box-body .editor-area-container {
  min-height: 10rem;
  max-height: 16rem;
}
