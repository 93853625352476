.selector-node {
  width: 100%;
}
.selector-node .node-summary-wrapper {
  padding: 0 0.75rem 0 0.75rem;
}
.selector-node .node-summary-wrapper:hover {
  background-color: #F8F9F9;
  cursor: pointer;
}
.selector-node .node-summary-wrapper.context-visible {
  background-color: #EBF5FF;
}
.selector-node .node-summary-wrapper.active {
  background-color: #f3f9ff;
}
.selector-node .node-summary {
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: center;
}
.selector-node .node-summary .icon-group {
  display: flex;
  align-items: center;
}
.selector-node .node-summary .icon-group > :not(:first-child) {
  margin-left: 2px;
}
.selector-node .node-summary .summary-line {
  display: flex;
  width: 100%;
}
.selector-node .node-summary .summary-line > :not(:first-child) {
  margin-left: 0.5rem;
}
.selector-node .node-summary .op-icon {
  padding-top: 2px;
  width: 12px;
  min-width: 12px;
  color: #B4B6B8;
}
.selector-node .node-imported-entity,
.selector-node .node-imported-field,
.selector-node .node-imported-virtual-field,
.selector-node .node-imported-data-interface {
  opacity: 0.6;
}
