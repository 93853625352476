.imageMaterials-content {
  display: flex;
  width: 100%;
  height: 28.875rem;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}
.imageMaterials-content .item {
  width: 14.25rem;
  height: 14.375rem;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  position: relative;
  cursor: pointer;
}
.imageMaterials-content .item img {
  width: 100%;
  height: 100%;
}
.imageMaterials-content .item .selected-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 100px;
  color: #fff;
  background-color: #000;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
}
