.pictures-wall ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pictures-wall .pictures-list.not-edit-mode .picture-item {
  cursor: pointer;
}
.pictures-wall .pictures-list.not-edit-mode .picture-item:not(:last-child) {
  margin-right: 0.5rem;
}
.pictures-wall .pictures-list.edit-mode .upload-button {
  margin-left: 0.75rem;
}
.pictures-wall .pictures-list.edit-mode .upload-button.no-img {
  margin-left: 0;
}
.pictures-wall .pictures-list .picture-item {
  position: relative;
  float: left;
}
.pictures-wall .pictures-list .picture-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.pictures-wall .pictures-list .picture-item.hovering .item-action-scarf {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pictures-wall .pictures-list .picture-item.hovering .item-action-scarf .icon {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.pictures-wall .pictures-list .picture-item.hovering .item-action-scarf .icon:first-child {
  margin-right: 8px;
}
.pictures-wall .pictures-list .picture-item .picture-item-img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.pictures-wall .pictures-list .picture-item .item-video-camera-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 16px;
}
.pictures-wall .pictures-list .picture-item .item-action-scarf {
  display: none;
}
.pictures-wall .upload-button {
  float: left;
  width: 120px;
  height: 120px;
  border: 1px dashed #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pictures-wall .upload-button .upload-icon {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.25);
}
