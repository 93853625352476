.lc-light-table .table-footer {
  height: 40px;
  display: flex;
  padding: 0.5rem 1rem;
  background-color: var(--color-neutral-2);
}
.lc-light-table .table-footer .footer-note {
  opacity: 0.75;
}
.lc-light-table .arco-table-footer {
  padding: 0;
}
