.outline-shown .lc-design-block {
  outline: 1px dashed rgba(170, 170, 170, 0.7);
}
.lc-design-block {
  position: relative;
  min-height: 1rem;
  outline-offset: -2px;
}
.lc-design-block.drop-parent {
  outline: 2px solid orange;
}
.lc-design-block.selected {
  outline: 2px solid #3b97e3;
}
.lc-design-block.being-dragged {
  opacity: 0.4;
}
.lc-design-block.row {
  display: flex;
}
.lc-design-block .placeholder-view {
  align-self: stretch;
  justify-self: stretch;
  flex-grow: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  padding: 1rem;
  min-height: 3rem;
  color: rgba(170, 170, 170, 0.7);
  outline: 1px dashed rgba(170, 170, 170, 0.5);
  outline-offset: -2px;
  height: 100%;
  width: 100%;
}
.lc-design-block .placeholder-view.drop-highlight {
  outline: 2px dashed #00D084;
  outline-offset: -2px;
}
.lc-design-block.hovered > .id-label {
  display: block;
}
.lc-design-block .id-label {
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0.5;
  user-select: none;
  font-size: 11px;
}
