.input-params-panel-group {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
}
.input-params-panel-group .input-params-panel {
  display: flex;
  flex-direction: column;
  min-width: 120px;
}
.input-params-panel-group .input-params-panel .func-params-group {
  min-width: 300px;
}
.input-params-panel-group .input-params-panel .input-panel {
  flex-grow: 1000;
}
.input-params-panel-group .input-params-panel .input-panel > .code-mirror-editor {
  height: 6rem;
}
.input-params-panel-group .input-params-panel .input-panel > .code-mirror-editor:not(:last-child) {
  margin-bottom: 0.5rem;
}
.input-params-panel-group .record-select-group {
  min-width: 200px;
  padding: 0.5rem 0;
}
.input-params-panel-group .caption,
.input-params-panel-group .button-group {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.input-params-panel-group .button-group {
  flex-direction: row-reverse;
}
