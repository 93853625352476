.admin--search.ant-input-search input {
  border-color: rgba(0, 0, 0, 0.2);
}
.admin--search.ant-input-search button .anticon {
  opacity: 0.8;
}
.admin--search.ant-input-search:hover input.ant-input,
.admin--search.ant-input-search:hover button {
  border-color: #64aad9;
}
.admin--search.ant-input-search:hover button .anticon {
  opacity: 0.95;
}
.admin--search.ant-input-search input.ant-input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  height: 30px;
}
.admin--search.ant-input-search input.ant-input:focus {
  border-color: #64aad9;
  box-shadow: none;
}
.admin--search.ant-input-search button {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
}
.admin--search.ant-input-search button:focus {
  border-bottom-color: #64aad9;
}
