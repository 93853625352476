.lc-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  opacity: 0.2;
}
.lc-page-designer {
  height: 100%;
  width: 100%;
  display: flex;
}
.lc-page-designer .resource-panel {
  width: 183px;
  z-index: 100;
  background-color: white;
}
.lc-page-designer .design-panel {
  flex-grow: 100;
}
.lc-page-designer .property-panel {
  width: 280px;
  z-index: 100;
  background-color: white;
}
.lc-page-designer .property-panel .arco-tabs,
.lc-page-designer .property-panel .arco-tabs-content-inner,
.lc-page-designer .property-panel .arco-tabs-pane {
  height: 100%;
}
.lc-page-designer .property-panel .arco-tabs-header-wrapper {
  height: 35px;
  justify-content: center;
}
.lc-page-designer .property-panel .arco-tabs-pane {
  overflow: scroll;
}
.lc-page-designer .property-panel .arco-tabs-content {
  height: calc(100% - 35px);
}
