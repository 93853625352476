.record-filter-display-control {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  max-width: 600px;
}
.record-filter-display-control .filter-display-item {
  display: flex;
  align-items: center;
}
.record-filter-display-control .filter-clear-all {
  font-size: 12px;
  margin-left: 4px;
  margin-top: 2px;
  vertical-align: middle;
}
