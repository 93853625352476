.pdfReader-container {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  overflow: hidden;
}
.pdfReader-container .pdfReader-content {
  display: flex;
  justify-content: center;
  height: 100%;
}
.pdfReader-container .pdfReader-scale-button-cot {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 1.5rem;
  bottom: 1.5rem;
}
.pdfReader-container .scale-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #d9d9d9;
  border-radius: 2rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.pdfReader-container .scale-button:hover {
  cursor: pointer;
  color: #64aad9;
  border-color: #64aad9;
}
.pdfReader-container .scale-button .scale-button-icon {
  font-size: 20px;
}
.pdfReader-container .scale-button .scale-button-icon::selection {
  color: #64aad9;
  background-color: #fff;
}
