.role-permission-input-container {
  border: 1px solid #f0f3f5;
  width: 100%;
  height: 30rem;
  display: flex;
}
.role-permission-input-container .entity-column,
.role-permission-input-container .permission-column {
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  padding: 1rem;
}
.role-permission-input-container .entity-column {
  border-right: 1px solid #f0f3f5;
}
.role-permission-input-container .entity-column .entity-item {
  padding: 0.5rem;
}
.role-permission-input-container .entity-column .entity-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.role-permission-input-container .entity-column .entity-item.focused {
  background-color: #f0f3f5;
}
.role-permission-input-container .entity-column .entity-item.selected {
  background-color: #c8cacc;
}
.role-permission-input-container .permission-column .ant-checkbox-wrapper {
  margin: 8px 0!important;
  width: 100%;
}
