.admin--edit-form-legacy {
  display: flex;
  flex-direction: column;
}
.admin--edit-form-legacy .form-item {
  margin-top: 20px;
  width: 100%;
}
.admin--edit-form-legacy .form-item:first-child {
  margin-top: 0;
}
.admin--edit-form-legacy .form-item .form-item-label {
  line-height: 2;
  position: relative;
  opacity: 0.8;
}
.admin--edit-form-legacy .form-item .error-message-cot {
  margin-top: 6px;
  font-size: smaller;
  color: #f5222d;
}
.admin--edit-form-legacy .form-item .form-item-label.item-require::before {
  content: "*";
  position: absolute;
  left: -10px;
  color: #f5222d;
}
.admin--edit-form-legacy .form-item .form-item-label.label-behind {
  margin-left: 8px;
}
.admin--edit-form-legacy .form-item .form-item-input {
  display: flex;
}
