.number-range-input {
  display: flex;
}
.number-range-input .rs-input {
  font-size: 11px;
  height: 30px;
  width: 7.5rem;
  border-radius: 4px;
}
.number-range-input .range-connector {
  display: flex;
  border: 1px solid #e5e5ea;
  border-left: none;
  border-right: none;
  min-width: 0.75rem;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
}
.number-range-input .left-input.rs-input:not(:hover):not(:active):not(:focus) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.number-range-input .right-input.rs-input:not(:hover):not(:active):not(:focus) {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
