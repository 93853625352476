.wxmp-text {
  padding: 1rem 2.25rem 1rem 1.25rem;
  border: 1px solid #e7e7eb;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
}
.wxmp-text:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.wxmp-text:hover div {
  display: block;
}
.wxmp-text p {
  color: #8d8d8d;
  padding-bottom: 0.625rem;
}
.wxmp-text label {
  width: 4.5rem;
  margin-right: 0.5rem;
}
.wxmp-text div {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}
