.edit-date-input {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
}
.edit-date-input .date-input-item {
  margin-right: 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.edit-date-input .date-input-item .date-input-col {
  width: 80%;
  margin-right: 8px;
}
.edit-date-input .date-input-item:hover .close-icon-col {
  display: flex;
  opacity: 0.7;
}
.edit-date-input .date-input-item.date-time-input-item {
  width: 220px;
}
.edit-date-input .date-input-item .close-icon-col {
  display: none;
  margin-left: 6px;
  cursor: pointer;
}
.edit-date-input .add-date-input-btn {
  margin-top: 2px;
}
