.wxmp-otherReply-container {
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}
.wxmp-otherReply-container .top {
  display: flex;
  flex-direction: column;
  color: #000;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #E4E8EB;
}
.wxmp-otherReply-container .top > span:first-child {
  font-size: 1.25rem;
}
.wxmp-otherReply-container .top > span:last-child {
  padding-top: 0.5rem;
  color: #aaa;
}
.wxmp-otherReply-container > div {
  margin-bottom: 2.5rem;
}
.wxmp-otherReply-container > div button {
  margin-right: 1rem;
}
.wxmp-otherReply-container > div label {
  min-width: 4rem;
  margin-right: 2rem;
  line-height: 2rem;
}
.wxmp-otherReply-container > div .replies-box {
  width: 50rem;
}
.wxmp-otherReply-container > div .replies-box .wxmp-replies {
  margin-bottom: 1rem;
}
.wxmp-otherReply-container .buttons {
  width: 50rem;
  text-align: center;
}
