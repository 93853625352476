.test-editor {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
}
.test-editor.test-editor-ai .rich-text {
  font-size: 14px;
}
.test-editor.test-editor-ai .slate-editor {
  height: 100%;
}
.test-editor.test-editor-ai .markdown-side {
  padding: 1.5rem 1rem;
  height: 100%;
  overflow: auto;
}
.test-editor.test-editor-ai .markdown-side pre {
  white-space: pre-line;
}
.test-editor .side {
  width: 50%;
  margin: 0 0.5rem;
}
.test-editor .side:last-child:first-child {
  max-width: 1024px;
  width: 90%;
  justify-self: center;
  margin: 16px auto;
}
.test-editor .draft-side .editor {
  overflow: auto;
}
