.colored-tag {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  padding: 0 0.5rem;
  font-size: 0.95em;
  height: 20px;
  line-height: 20px;
}
.colored-tag:not(:last-child) {
  margin-right: 0.5rem;
}
