.wx-input-container {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  padding: 0.75rem;
}
.wx-input-container > div:not(:last-child) {
  margin-bottom: 0.5rem;
}
.wx-input-container .add-attachment {
  position: relative;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f0f3f5;
}
.wx-input-container .add-attachment .add-icon {
  font-size: 20px;
  color: #8c8d8f;
}
.wx-input-container .attachment-choose {
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid #f0f3f5;
}
.wx-input-container .add-attachment-box {
  position: relative;
  padding: 0.5rem 4.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wx-input-container .add-attachment-box.attachment-edit {
  min-height: 9rem;
}
.wx-input-container .add-attachment-box.attachment-edit.link-edit {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.wx-input-container .add-attachment-box.attachment-edit.link-edit .action-box {
  display: flex;
}
.wx-input-container .add-attachment-box.attachment-edit:hover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.wx-input-container .add-attachment-box.attachment-edit:hover .action-box {
  display: flex;
}
.wx-input-container .add-attachment-box.attachment-edit .ant-upload.ant-upload-drag {
  height: 8rem;
}
.wx-input-container .add-attachment-box.attachment-edit .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-drag-icon,
.wx-input-container .add-attachment-box.attachment-edit .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-hint {
  margin: 1.5rem 0;
}
.wx-input-container .add-attachment-box.attachment-edit .video {
  width: 100%;
  padding: 1rem;
}
.wx-input-container .add-attachment-box .link-input {
  width: 100%;
  padding: 1rem 0;
}
.wx-input-container .add-attachment-box .link-input > div {
  display: flex;
}
.wx-input-container .add-attachment-box .link-input > div > span {
  width: 2.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-right: 1rem;
}
.wx-input-container .add-attachment-box .link-input > div:not(:last-child) {
  margin-bottom: 1rem;
}
.wx-input-container .action-box {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
}
.wx-input-container .action-box > div {
  width: 2em;
  height: 2em;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.wx-input-container .action-box > div.confirm,
.wx-input-container .action-box > div.edit {
  opacity: 0.8;
}
.wx-input-container .action-box > div.confirm:hover,
.wx-input-container .action-box > div.edit:hover {
  background-color: #f0f3f5;
  border-radius: 50%;
}
.wx-input-container .action-box > div.confirm {
  margin-right: 0.5rem;
  color: #00A854;
  opacity: 0.8;
}
.wx-input-container .action-box > div.edit {
  margin-right: 0.5rem;
  color: #3E8ECD;
}
.wx-input-container .attachment-item {
  position: relative;
  padding: 1.5rem 4.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f3f5;
}
.wx-input-container .attachment-item:hover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.wx-input-container .attachment-item:hover .action-box {
  display: flex;
}
.wx-input-container .attachment-item .wx-text {
  width: 100%;
  white-space: pre-line;
  word-break: break-word;
}
.wx-input-container .attachment-item > img {
  max-width: 200px;
  object-fit: contain;
  cursor: pointer;
}
.wx-input-container .attachment-item > .file,
.wx-input-container .attachment-item > .link {
  display: flex;
  padding: 0.5rem;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #f0f3f5;
}
.wx-input-container .attachment-item > .file .title,
.wx-input-container .attachment-item > .link .title {
  width: 100%;
  height: 2.5rem;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
  word-break: break-word;
}
.wx-input-container .attachment-item > .file .content,
.wx-input-container .attachment-item > .link .content {
  display: flex;
}
.wx-input-container .attachment-item > .file .content > div:first-child,
.wx-input-container .attachment-item > .link .content > div:first-child {
  width: 100%;
  height: 3.75rem;
  font-size: 12px;
  color: #8c8d8f;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-right: 10px;
  word-break: break-word;
  text-align: justify;
}
.wx-input-container .attachment-item > .file > img,
.wx-input-container .attachment-item > .link > img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2px;
}
.wx-input-container .attachment-item > .link {
  flex-direction: column;
}
.wx-input-container .attachment-item > .link > .title {
  margin-bottom: 0.5rem;
}
.wx-input-container .attachment-item > .link img,
.wx-input-container .attachment-item > .link .dummy-img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 2px;
}
.wx-input-container .attachment-item > .link .dummy-img {
  background-color: #eee;
  color: #fff;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.wx-input-container .attachment-item .video {
  width: 100%;
  padding: 1rem;
}
