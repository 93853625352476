.lc-form-field-management .arco-table-th,
.lc-form-field-management .arco-table-td {
  font-size: 12px;
}
.lc-form-field-management .arco-table-th:not(:first-child),
.lc-form-field-management .arco-table-td:not(:first-child) {
  border-left: 0.75px solid var(--color-neutral-3);
}
.lc-form-field-management .arco-textarea {
  font-size: 12px;
}
.lc-form-field-management .arco-table-td {
  padding: 0;
}
.lc-form-field-management .cell-value-display {
  padding: 9px 16px;
  white-space: pre-line;
}
.lc-form-field-management .cell-value-input {
  padding: 4px 8px;
}
.lc-form-field-management .operation-box {
  display: flex;
  justify-content: center;
}
.lc-form-field-management .operation-box .rs-btn {
  padding: 4px 8px;
}
.lc-form-field-management-modal {
  padding: 1rem 1.5rem;
}
