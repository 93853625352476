.top-bar {
  height: 3rem;
  min-height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #3987CC;
  color: white;
}
.top-bar .operation-button-box .button-panel {
  top: 3rem;
}
.top-bar.small {
  height: 2.75rem;
  min-height: 2.75rem;
}
.top-bar.small .site-logo {
  width: 1.25rem;
  height: 1.25rem;
}
.top-bar.small .site-name {
  font-size: 13px;
  font-weight: 500;
}
.top-bar.small .operation-button-box .button-panel {
  top: 2.75rem;
}
.top-bar.small .user-box {
  margin-left: 0.25rem;
}
.top-bar.small .user-box .user-avatar {
  width: 1.25rem;
  height: 1.25rem;
}
.top-bar .application-name {
  padding-top: 1px;
}
.top-bar .top-bar-button {
  padding: 0 0.75rem 0;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.top-bar .top-bar-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.top-bar .top-bar-button:active,
.top-bar .top-bar-button.active {
  background-color: rgba(0, 0, 0, 0.05);
}
.top-bar .app-selector-button {
  padding: 1px 0.75rem 0;
  font-size: 15px;
}
.top-bar .site-name {
  font-size: 16px;
  margin-left: 0.25rem;
  letter-spacing: 0.05em;
}
.top-bar .site-logo-wrapper {
  padding: 0.2rem;
  background-color: white;
  padding: 1px;
  border-radius: 2px;
}
.top-bar .site-logo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
}
.top-bar .item-group {
  display: flex;
  align-items: center;
  height: 100%;
}
.top-bar.small > .operation-button-box > .button-panel {
  top: 2.75rem;
}
.top-bar .operation-panel {
  width: 16rem;
  min-height: 9rem;
  max-height: 32rem;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
}
.top-bar .operation-panel.empty-state {
  color: rgba(0, 0, 0, 0.35);
}
.app-selector-drawer {
  width: 320px!important;
}
.app-selector-drawer .rs-drawer-content {
  padding: 2rem;
}
.app-selector-drawer .app-selector-head {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.app-selector-drawer .app-selector-head > :not(:last-child) {
  margin-right: 1rem;
}
.app-selector-drawer .app-selector-caption {
  opacity: 0.7;
  padding: 1rem;
}
.app-selector-drawer .site-name {
  font-size: 18px;
}
.app-selector-drawer .site-logo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
}
.app-selector-drawer .app-selector-divider {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.app-selector-drawer .app-list {
  font-size: 13px;
}
.app-selector-drawer .app-list .app-item {
  padding: 1rem;
  cursor: pointer;
}
.app-selector-drawer .app-list .app-item.current {
  background-color: #f0fbff;
}
.app-selector-drawer .app-list .app-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
