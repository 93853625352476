.entity-data-page--record-details {
  flex: 1;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.entity-data-page--record-details .data-tabs {
  flex: 1;
  overflow: hidden;
  min-width: 400px;
}
.entity-data-page--record-details .util-tabs {
  margin-top: 4rem;
  margin-right: 0.75rem;
  background-color: white;
  max-width: 38%;
}
