.shadows-list {
  padding: 4px;
  background-color: #f0f3f5;
  margin-top: 4px;
}
.shadows-list .shadow-item {
  padding: 6px 8px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadows-list .shadow-item:not(:last-child) {
  border-bottom: 1px solid #c8cacc;
}
.shadows-list .shadow-item:hover {
  padding: 6px 3rem 6px 8px;
}
.shadows-list .shadow-item:hover .actions {
  display: flex;
}
.shadows-list .shadow-item:hover .actions.hide {
  display: none;
}
.shadows-list .shadow-item .actions {
  display: none;
  align-items: center;
  font-size: 14px;
  color: #8c8d8f;
  position: absolute;
  right: 4px;
  top: 8px;
}
.shadows-list .shadow-item .actions > div {
  display: flex;
}
.shadows-list .shadow-item .actions > div:first-child {
  margin-right: 8px;
}
.shadows-list .shadow-item .actions svg:hover {
  fill: #27282d;
}
.shadows-list .shadow-item .shadow-color-display {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  float: left;
  margin-top: 3px;
}
