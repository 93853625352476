.bw-popconfirm {
  z-index: 1999;
}
.bw-popconfirm .content-wrapper {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 4px;
  padding: 12px 16px;
}
.bw-popconfirm .content-wrapper .rs-btn {
  margin-left: 8px;
}
.bw-popconfirm .content-wrapper .bw-popconfirm-message {
  display: flex;
  align-items: center;
  padding: 4px 0 12px;
  font-size: 12px;
}
.bw-popconfirm .content-wrapper .bw-popconfirm-message > p {
  margin-left: 6px;
}
.bw-popconfirm .content-wrapper .bw-popconfirm-buttons {
  display: flex;
  justify-content: flex-end;
}
.bw-popconfirm .content-wrapper .bw-popconfirm-buttons button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}
