.position-edit-area {
  height: 72px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.position-edit-area .position-edit-dot {
  grid-column-start: 1;
  grid-row: 1 / span 3;
  width: 72px;
  height: 72px;
  background-color: #f0f3f5;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.position-edit-area .position-edit-dot .single-dot {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 8px;
}
.position-edit-area .position-edit-dot .single-dot .position-left,
.position-edit-area .position-edit-dot .single-dot .position-right {
  display: flex;
  align-items: center;
}
.position-edit-area .position-edit-dot .single-dot .position-left > div,
.position-edit-area .position-edit-dot .single-dot .position-right > div {
  flex-direction: column;
  display: flex;
}
.position-edit-area .position-edit-dot .single-dot .position-left {
  padding-left: 8px;
}
.position-edit-area .position-edit-dot .single-dot .position-right {
  padding-right: 8px;
}
.position-edit-area .position-edit-dot .single-dot .position-top,
.position-edit-area .position-edit-dot .single-dot .position-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.position-edit-area .position-edit-dot .single-dot .position-top > div,
.position-edit-area .position-edit-dot .single-dot .position-bottom > div {
  display: flex;
}
.position-edit-area .position-edit-dot .single-dot .position-top {
  padding-top: 8px;
}
.position-edit-area .position-edit-dot .single-dot .position-bottom {
  padding-bottom: 8px;
}
.position-edit-area .position-input {
  display: flex;
}
.position-edit-area .position-input > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.position-edit-area .position-input > div:last-child {
  margin-left: 0.5rem;
}
.position-edit-area .position-input .label {
  font-size: 10px;
  color: #8c8d8f;
}
.position-edit-area .arco-input-group-suffix {
  width: 18px;
}
.position-edit-area .arco-input-size-mini {
  font-size: 10px!important;
}
