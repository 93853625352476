.reply-edit-page {
  height: 100%;
  margin: 0 1rem;
  padding: 1rem;
  background-color: #fff;
}
.reply-edit-page .top {
  display: flex;
  flex-direction: column;
  color: #000;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #E4E8EB;
}
.reply-edit-page .top > span:first-child {
  font-size: 1.25rem;
}
.reply-edit-page .top > span:last-child {
  padding-top: 0.5rem;
  color: #aaa;
}
.reply-edit-page > div {
  margin-bottom: 2.5rem;
}
.reply-edit-page > div button {
  margin-right: 1rem;
}
.reply-edit-page > div label {
  min-width: 4rem;
  margin-right: 2rem;
  line-height: 2rem;
}
.reply-edit-page > div .keyword-select {
  width: 7.5rem;
}
.reply-edit-page > div .keyword-select.error .ant-select-selection {
  border: 1px solid #f00;
  border-top-width: 1.02px;
}
.reply-edit-page > div .publish-fault {
  color: #f00;
}
.reply-edit-page > div .replies-box {
  width: 50rem;
}
.reply-edit-page > div .replies-box .wxmp-replies {
  margin-bottom: 1rem;
}
.reply-edit-page .keyword-pattern > div:not(:last-child) {
  margin-bottom: 2rem;
}
.reply-edit-page .keyword-pattern > div:not(:first-child) .input,
.reply-edit-page .keyword-pattern > div:not(:first-child) .publish-fault {
  margin-left: 6rem;
}
.reply-edit-page .keyword-pattern .input {
  display: flex;
  align-items: center;
}
.reply-edit-page .keyword-pattern .input > span {
  margin-left: 0.625rem;
  border-radius: 100%;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.reply-edit-page .keyword-pattern .input > span:hover {
  background-color: #f0f0f0;
}
.reply-edit-page .buttons {
  width: 50rem;
}
.reply-edit-page .buttons button {
  margin: 0 0.5rem;
}
