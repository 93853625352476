.active-tools-bar {
  position: absolute;
  display: flex;
}
.active-tools-bar.bar-on-left {
  left: 1px;
}
.active-tools-bar.bar-on-right {
  right: 1px;
}
.active-tools-bar.bar-on-top {
  top: -1.5rem;
}
.active-tools-bar.bar-at-bottom {
  bottom: -1.5rem;
}
.active-tools-bar .action-button-group {
  pointer-events: all;
  display: flex;
  padding: 0 0.125rem;
  align-items: stretch;
  background-color: #3b97e3;
  color: white;
  font-size: 14px;
  height: 22px;
  border-radius: 2px;
}
.active-tools-bar .action-button-group:not(:last-child) {
  margin-right: 2px;
}
.active-tools-bar .action-button-group .action-button {
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
}
.active-tools-bar .action-button-group .action-button:hover {
  cursor: pointer;
}
