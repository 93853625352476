.admin--topbar-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.admin--topbar-main .topbar {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
}
.admin--topbar-main .topbar .top-left-bar > button,
.admin--topbar-main .topbar .top-left-bar > div {
  margin-right: 0.5rem;
}
.admin--topbar-main .topbar .top-right-bar > button {
  margin-left: 1rem;
}
.admin--topbar-main .topbar .top-right-bar > div {
  margin-left: 0.5rem;
}
.admin--topbar-main .topbar .top-left-bar,
.admin--topbar-main .topbar .top-right-bar {
  display: flex;
  align-items: center;
}
