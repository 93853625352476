.poster-preview-cot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poster-preview-cot .preview-bg {
  display: flex;
  flex-direction: column;
}
.poster-preview-cot .preview-bg img {
  width: 80%;
  margin: 0 auto;
}
