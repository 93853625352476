.tags-edit-card {
  position: relative;
}
.tags-edit-card.editing {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.tags-edit-card.ant-card {
  background: transparent;
}
.tags-edit-card.ant-card .ant-card-body {
  padding: 24px 20px 8px;
}
.tags-edit-card:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.tags-edit-card:hover .anticon-edit {
  display: block;
}
.tags-edit-card .editing-actions,
.tags-edit-card .anticon-edit {
  position: absolute;
  top: 8px;
  right: 8px;
}
.tags-edit-card .anticon-edit {
  display: none;
  color: #3E8ECD;
}
.tags-edit-card .editing-actions .anticon-check {
  color: #00A854;
  margin-right: 8px;
}
.tags-edit-card .editing-actions .check-disabled {
  cursor: default;
  opacity: 0.5;
}
.tags-edit-card .ant-tag {
  margin-bottom: 8px;
}
.tags-edit-card .anticon-plus {
  font-size: 12px;
  padding: 0 6px;
  cursor: pointer;
  display: inline-block;
}
