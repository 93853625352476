.admin-layout {
  padding: 0 8px 6px;
}
.admin-layout.one-widget {
  height: 100%;
  padding: 0 16px 12px;
}
.admin-layout.admin-layout-widget-and-bar {
  display: flex;
  height: 100%;
}
.admin-layout.admin-layout-widget-and-bar.reverse {
  flex-direction: row-reverse;
}
.admin-layout.admin-layout-widget-and-bar .main-widget {
  width: 66.67%;
  max-width: 66.67%;
  min-width: 66.67%;
  padding: 0 8px 6px;
}
.admin-layout.admin-layout-widget-and-bar .side-bar {
  width: 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
  overflow: auto;
}
.admin-layout.wide-and-narrow,
.admin-layout .wide-and-narrow {
  width: 100%;
  height: auto;
  display: flex;
}
.admin-layout .wide-column {
  width: 66.67%;
  max-width: 66.67%;
  min-width: 66.67%;
}
.admin-layout .narrow-column {
  width: 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
}
.admin-layout .one-column {
  display: flex;
  flex-direction: column;
}
.admin-layout .two-columns {
  display: flex;
}
.admin-layout .two-columns > div {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
}
.admin-layout .widget-box {
  display: flex;
  padding: 6px 8px;
}
.admin-layout .widget-box.no-top-padding {
  padding: 0 8px 6px;
}
.admin-layout .widget-box.multiple-widgets > :not(:last-child) {
  padding-right: 8px;
}
.admin-layout .widget-box.multiple-widgets > :not(:first-child) {
  padding-left: 8px;
}
.admin-layout .admin-widget {
  background-color: white;
  padding: 12px 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.admin-layout .admin-widget:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
}
.admin-layout .admin-widget .widget-head {
  display: flex;
  padding: 0 0 0.5rem 0;
  align-items: center;
  height: 36px;
  width: 100%;
  justify-content: space-between;
}
.admin-layout .admin-widget .widget-head .widget-title {
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  display: flex;
}
.admin-layout .admin-widget .widget-head .widget-title .title-line {
  display: flex;
}
.admin-layout .admin-widget .widget-head .widget-title > :not(:last-child) {
  margin-right: 0.5rem;
}
.admin-layout .admin-widget .widget-head .widget-title .description-line {
  font-weight: normal;
  font-size: 12px;
  opacity: 0.6;
  padding-top: 4px;
}
.admin-layout .admin-widget .widget-head .icon-button {
  opacity: 0.7;
}
.admin-layout .admin-widget .widget-head .icon-button:hover {
  opacity: 0.9;
}
.admin-layout .admin-widget .widget-body {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
}
.admin-layout .admin-widget.with-head .widget-body {
  height: calc(100% - 36px);
}
.admin--edit-modal .ant-modal-footer button + button {
  margin-left: 12px;
}
.admin--edit-modal .ant-modal-footer .button + .button {
  margin-left: 12px;
}
.admin--edit-modal.bw-ui .button {
  display: inline-block;
}
.admin--edit-modal-confirm {
  padding-top: 5rem;
}
