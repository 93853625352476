.popover-menu-overlay {
  padding: 0;
}
.popover-menu-overlay *:focus {
  outline: 0;
}
.popover-menu-overlay .menu-list {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  border-radius: 6px;
}
.popover-menu-overlay .menu-list > div:not(:last-child) {
  border-bottom: 1px #F5F5F5 solid;
}
