.admin--display-plain {
  word-break: break-all;
  display: inline-block;
  white-space: pre-line;
}
.admin--display-plain a {
  text-decoration: underline;
  margin-right: 1em;
}
.admin--display-plain a:last-child {
  margin-right: 0;
}
