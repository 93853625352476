.admin--icon-button {
  height: 1.75em;
  width: 1.75em;
  border-radius: 50%;
  opacity: 0.75;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin--icon-button:hover {
  opacity: 1;
  background-color: #F0F3F5;
}
.admin--icon-button:active {
  background-color: #DCDEE0;
}
.admin--icon-button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.admin--icon-button.disabled:hover {
  background-color: inherit;
}
.admin--icon-button.disabled:active {
  background-color: inherit;
}
