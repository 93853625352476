.record-col-overflow {
  width: 100%;
  position: relative;
  padding-right: 20px;
}
.record-col-overflow .overflow-info-circle {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}
td .record-col-overflow .record-col-overflow-cot {
  padding-top: 4px;
  overflow: hidden;
  max-height: 160px;
}
td .record-col-overflow .overflow-info-circle {
  display: block;
}
td .record-col-overflow.overflow-col-hover:hover {
  cursor: pointer;
}
