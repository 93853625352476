.admin--record-card {
  overflow: auto;
  height: 100%;
  min-height: 8rem;
  position: relative;
  padding: 12px 0 16px !important;
}
.admin--record-card.with-head {
  min-height: 10rem;
}
.admin--record-card .widget-head {
  padding-right: 12px !important;
  padding-left: 16px !important;
}
.admin--record-card .widget-head .widget-title {
  margin-right: 8px;
}
.admin--record-card.with-head > .record-card-body {
  height: calc(100% - 36px);
  position: relative;
}
.admin--record-card.with-head > .record-card-body .head-top-right-buttons {
  position: absolute;
}
.admin--record-card .record-card-body {
  height: 100%;
  padding: 4px 24px;
  overflow: auto;
}
.admin--record-card .edit-button {
  display: none;
}
.admin--record-card:hover .record-card-body {
  overflow: auto;
}
.admin--record-card:hover .edit-button {
  display: flex;
}
.admin--record-card .top-right-bar {
  display: flex;
  align-items: center;
}
.admin--record-card .top-right-bar > :not(:last-child) {
  margin-right: 0.5rem;
}
.admin--record-card .element-view-box {
  height: 100%;
  width: 100%;
}
.admin--record-card.single-field {
  min-height: auto;
}
.admin--record-card.single-field .record-card-body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.admin--record-card.single-field .rich-media,
.admin--record-card.single-field .admin--richtext-input,
.admin--record-card.single-field .article-editor {
  max-width: 45rem;
}
.admin--record-card.single-field .admin--richtext-input {
  border: none;
  padding: 0;
}
.admin--record-card.single-field .article-editor {
  border: none;
  padding: 0;
}
.admin--record-card.single-field .article-editor .editor-toolbar {
  border-bottom: 1px solid #f0f0f4;
}
.admin--record-card.single-field .article-editor .editor {
  overflow: auto;
}
.admin--record-card.single-field.is-main .rich-text {
  padding: 0.5em 0 1rem;
}
.admin--record-card.single-field.is-main .rich-text,
.admin--record-card.single-field.is-main .admin--richtext-input,
.admin--record-card.single-field.is-main .article-editor {
  font-size: 14px;
  text-align: justify;
}
.admin--edit-modal .admin--record-card {
  min-height: 12rem;
  padding-top: 2rem !important;
  padding-bottom: 4rem !important;
  padding-left: 1rem !important;
}
.admin--edit-modal .admin--record-card .layout-horizontal {
  padding-right: 5rem;
}
.admin--edit-modal .admin--record-card .layout-vertical {
  padding-right: 2rem;
}
