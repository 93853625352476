.admin--record-list-view .record-cell {
  padding: 1rem 0;
}
.admin--record-list-view .record-cell:not(:last-child) {
  border-bottom: 1px solid #fafafa;
}
.admin--record-list-view .record-list {
  overflow: auto;
  flex-grow: 1;
}
.admin--record-list-view .record-list-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem;
  background-color: #fafafa;
}
