.lc-multi-text-display {
  display: flex;
  flex-direction: column;
}
.lc-multi-text-display > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.lc-multi-text-display .text-part {
  background-color: #fafafb;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
.lc-multi-text-popover {
  white-space: pre-line;
  line-break: anywhere;
  font-size: 12px;
  color: #27282d;
}
