.admin-chart-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-chart-card .card-value {
  font-size: 3rem;
}
.admin-chart-card .card-label {
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
