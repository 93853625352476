.admin--edit-box {
  cursor: default;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;
  padding-right: 2.5rem;
  background-color: white;
}
.admin--edit-box.maximum {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding-right: 2.5rem;
  background-color: white;
  z-index: 200;
}
.admin--edit-box.maximum .edit-btn-box {
  display: flex;
  flex-direction: column-reverse;
  right: 8px;
  padding-top: 0.5rem;
}
.admin--edit-box.maximum .edit-btn-box > :not(:last-child) {
  margin-right: 0;
  margin-top: 0.5rem;
}
.admin--edit-box .edit-btn-box {
  position: absolute;
  display: flex;
  top: 0px;
  right: 0px;
  flex-direction: column-reverse;
  right: 8px;
  padding-top: 0.5rem;
}
.admin--edit-box .edit-btn-box > :not(:last-child) {
  margin-right: 0;
  margin-top: 0.5rem;
}
.admin--edit-box .edit-btn-box .edit-button {
  color: #3E8ECD;
}
.admin--edit-box .edit-btn-box .confirm-button {
  color: #00A854;
}
.admin--edit-box .edit-btn-box .button:hover {
  background-color: #f5f5f5;
}
.admin--edit-box .edit-btn-box .button {
  font-size: larger;
  padding: 4px;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin--edit-box .edit-btn-box .button.disabled {
  opacity: 0.15;
  cursor: default;
}
