.test-language {
  background-color: white;
  height: 100%;
  width: 100%;
}
.test-language .status-bar {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}
.test-language .status-bar .error {
  color: #FF4760;
}
.test-language .main-area {
  height: calc(100% - 32px);
  display: flex;
}
.test-language .main-area .code-editor {
  height: 100%;
  width: 50%;
}
.test-language .main-area .language-analyzer {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.test-language .main-area .language-analyzer .analyzer-panel {
  padding: 4px 1rem;
  overflow: auto;
  flex-grow: 1;
}
.test-language .main-area .language-analyzer .syntax-node {
  width: 100%;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  align-items: center;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary:hover {
  background-color: #EBF5FF;
  cursor: pointer;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary .subtle {
  opacity: 0.6;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary .smaller {
  font-size: small;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary .summary-text {
  display: flex;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary .summary-text span:not(:first-child) {
  margin-left: 0.5rem;
}
.test-language .main-area .language-analyzer .syntax-node .node-summary .op-icon {
  padding-top: 2px;
  margin-left: 8px;
  color: #B4B6B8;
}
