.draggable-input .anticon {
  color: rgba(0, 0, 0, 0.4);
}
.draggable-input .draggable-input-item {
  display: flex;
  align-items: center;
}
.draggable-input .draggable-input-item .base-input-col {
  flex: 17;
}
.draggable-input .draggable-input-item .drag-item,
.draggable-input .draggable-input-item .drop-item {
  cursor: move;
}
.draggable-input .draggable-input-item .drag-item,
.draggable-input .draggable-input-item .close-icon-col {
  flex: 1;
  width: 40px;
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}
.draggable-input .add-input-btn {
  width: calc(100% - 40px);
  border-width: 1px;
}
.custom-drag-layer .drag-layer-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.custom-drag-layer .layer-item-input-col {
  height: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  white-space: pre-wrap;
}
.custom-drag-layer .layer-item-close-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
