.bw-admin .bw-admin--menu-btn .rs-btn {
  padding: 8px 24px;
  width: 100%;
  border-radius: 0;
}
.bw-admin .bw-admin--menu-btn .rs-btn:not(:disabled) {
  color: #3C3D3D;
}
.bw-admin .bw-admin--menu-btn .rs-btn.rs-btn-ghost {
  border: 0;
}
