.studio-context-popup {
  display: none;
  background-color: #f5f7f9;
  z-index: 100;
  position: absolute;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.studio-context-popup.visible {
  display: flex;
}
