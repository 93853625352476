.preview-page {
  font-size: 16px;
  background-color: white;
}
.preview-page ::selection {
  background-color: #E0ECFF;
  color: inherit;
}
.preview-page #app {
  height: 100%;
}
.preview-page a {
  color: inherit;
}
