.bw-text {
  letter-spacing: 0.03em;
  font-family: Regular, PingFang-Regular, 'Microsoft YaHei', sans-serif;
}
.bw-text-body {
  font-size: 1rem;
}
.bw-text-body-em {
  font-size: 1rem;
  font-family: SemiBold, PingFang-Semibold, 'Microsoft YaHei', sans-serif;
  font-weight: 600;
}
.bw-text-body-small {
  font-size: 0.875rem;
}
.bw-text-body-small-em {
  font-size: 0.875rem;
  font-family: SemiBold, PingFang-Semibold, 'Microsoft YaHei', sans-serif;
  font-weight: 600;
}
.bw-text-helper {
  font-size: 0.75rem;
}
.bw-text-caption {
  font-size: 0.78125rem;
  transform: scale(0.8);
  -webkit-transform-origin-x: left;
}
.bw-text-quote {
  font-size: 0.875rem;
  font-weight: 300;
  font-family: Light, PingFang-Light, 'Microsoft YaHei', sans-serif;
}
