.admin--record-table {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.admin--record-table .topbar {
  display: flex;
  justify-content: space-between;
}
.admin--record-table .topbar .top-left-bar > button,
.admin--record-table .topbar .top-left-bar > div {
  margin-right: 0.5rem;
}
.admin--record-table .topbar .top-right-bar > .button,
.admin--record-table .topbar .top-right-bar > button {
  margin-left: 0.5rem;
}
.admin--record-table .topbar .top-right-bar > div {
  margin-left: 0.25rem;
}
.admin--record-table .topbar .top-left-bar,
.admin--record-table .topbar .top-right-bar {
  display: flex;
  align-items: center;
}
.admin--record-table .table-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.admin--record-table .table-button-box .operation-menu-icon {
  position: absolute;
  right: -8px;
  height: 100%;
  display: flex;
  align-items: center;
}
.admin--record-table .bwax-table .table-footer {
  padding: 0.5rem;
}
.admin--record-table .bwax-table .admin--display-text {
  max-height: 160px;
  padding-right: 8px;
  overflow-y: hidden;
}
.admin--record-table .bwax-table .admin--display-text:hover {
  overflow-y: auto;
  padding-right: 0;
}
.admin--record-table .initializing-body-container {
  position: relative;
}
.admin--record-table .initializing-body-container .initializing-fixed-right-body {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -4px 0px 4px -4px rgba(0, 0, 0, 0.25);
}
