.admin--record-op-panel {
  padding: 1rem;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
}
.admin--record-op-panel .title {
  opacity: 0.8;
}
.admin--record-op-panel > :not(:last-child) {
  margin-bottom: 1rem;
}
.admin--record-op-panel .button-bar {
  display: flex;
  justify-content: flex-end;
}
.admin--record-op-panel .button-bar > :not(:last-child) {
  margin-right: 1rem;
}
