.gradient-edit-container .gradient-bar-container {
  width: 100%;
  margin-top: 8px;
  position: relative;
  cursor: copy;
}
.gradient-edit-container .gradient-bar-container .gradient-bar-inner {
  width: 100%;
  height: 16px;
  background-repeat: no-repeat, repeat;
  background-size: auto, 16px 16px;
}
.gradient-edit-container .gradient-bar-container .gradient-box {
  position: absolute;
  height: calc(100% - 2px);
  top: 1px;
  width: 8px;
  border-radius: 1px;
  opacity: 1;
  cursor: ew-resize;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 1px;
}
.gradient-edit-container .gradient-bar-container .gradient-box.active {
  box-shadow: #ebebeb 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 0px 0px 3px, rgba(0, 0, 0, 0.3) 0px 0px 0px 1px inset;
}
.gradient-edit-container .repeat-or-reverse {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gradient-edit-container .repeat-or-reverse .repeat {
  display: flex;
  align-items: center;
}
.gradient-edit-container .repeat-or-reverse .reverse {
  display: flex;
  font-size: 16px;
}
.gradient-edit-container .color-edit {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.gradient-edit-container .color-edit .color-input {
  display: flex;
  margin-left: 24px;
}
.gradient-edit-container .color-edit .color-input .gradient-color {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-size: cover;
}
.gradient-edit-container .color-edit .color-input .arco-input-inner-wrapper {
  padding-left: 4px!important;
}
