.outdated-page-list {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;
}
.outdated-page-list > :not(:last-child) {
  margin-bottom: 1rem;
}
.outdated-page-list .od-container {
  border: solid 1px #F0F3F5;
}
.outdated-page-list .od-container .od-title {
  padding: 0.5rem 1rem;
  background-color: #F0F3F5;
}
.outdated-page-list .od-container .od-row {
  display: flex;
}
.outdated-page-list .od-container .od-row .od-name {
  width: 10rem;
  padding: 0.5rem 1rem;
  border-right: solid 1px #F0F3F5;
}
.outdated-page-list .od-container .od-row .od-inc-list {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.outdated-page-list .od-container .od-row .od-inc-list > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.outdated-page-list .od-container .od-row .od-inc-list .fn-name {
  margin-right: 0.5rem;
}
.outdated-page-list .od-container .od-row .od-inc-list .od-details {
  padding: 0.25rem 0;
  width: 100%;
}
.outdated-page-list .od-container .od-row .od-inc-list .od-details pre {
  border-radius: 2px;
  font-size: 11px;
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.02);
}
