.effects-container .attr-label {
  width: 54px;
  flex-shrink: 0;
}
.effects-container .opacity-edit-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.effects-container .opacity-edit-container .slider-input {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}
.effects-container .cursor-edit-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.effects-container .boxShadow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 8px 0;
}
.effects-container .boxShadow-header .icon-add {
  font-size: 16px;
  cursor: pointer;
  display: flex;
}
.effects-container .boxShadow-header .icon-add.hide {
  display: none;
}
.cursor-select-dropdown {
  max-height: 600px!important;
}
.cursor-select-dropdown .cursor-category {
  background-color: #F7F8FA;
  padding: 4px;
  font-weight: bold;
}
