.design--resource-panel {
  margin-top: -1rem;
  height: 80vh;
  overflow: auto;
}
.design--resource-panel .resource-list .ant-upload.ant-upload-drag svg {
  display: inline;
}
.design--resource-panel .resource-list .row {
  display: flex;
}
.design--resource-panel .resource-list .row .cell-wrapper {
  flex-grow: 1;
  width: 33.333%;
  padding: 1rem;
}
.design--resource-panel .resource-list .row .cell {
  width: 100%;
  aspect-ratio: 1.3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition-duration: 3000ms;
  position: relative;
}
.design--resource-panel .resource-list .row .cell .mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.design--resource-panel .resource-list .row .cell .mask > :not(:last-child) {
  margin-right: 0.5rem;
}
.design--resource-panel .resource-list .row .cell .mask svg {
  cursor: pointer;
}
.design--resource-panel .resource-list .row .cell:hover .mask {
  opacity: 1;
}
