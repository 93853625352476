.poster-setting-display .poster-setting-display-item {
  margin-bottom: 1.25rem;
}
.poster-setting-display .poster-setting-display-item:first-child {
  overflow: hidden;
}
.poster-setting-display .poster-setting-display-item .display-item-label {
  margin-bottom: 0.5rem;
}
.poster-setting-display .poster-setting-display-item .display-item-col {
  margin-right: 0.75rem;
}
