.admin--display-comment {
  display: flex;
  margin: 1rem 0;
}
.admin--display-comment .avatar {
  margin-right: 0.5rem;
}
.admin--display-comment .avatar img {
  border-radius: 50%;
  height: 1.5rem;
}
.admin--display-comment .content {
  flex: 1;
}
.admin--display-comment .content .content-header {
  display: flex;
  justify-content: space-between;
}
.admin--display-comment .content .content-header .control-bar {
  opacity: 0.6;
  display: flex;
}
.admin--display-comment .content .content-header .control-bar > :not(:last-child) {
  margin-right: 0.5rem;
}
.admin--display-comment .content .content-body {
  margin: 0.5rem 0;
}
.admin--display-comment .content .content-body .edit-box {
  margin: 1rem 0;
}
.admin--display-comment .content .content-body .edit-box .edit-toolbar {
  margin-top: 1rem;
  display: flex;
  margin-left: -0.1rem;
}
.admin--display-comment .content .content-body .edit-box .edit-toolbar > :not(:last-child) {
  margin-right: 0.5rem;
}
.admin--display-comment .content .content-footer {
  margin-top: 0.5rem;
  opacity: 0.5;
}
