.input--record-filter {
  min-width: 25rem;
}
.input--record-filter .rs-toggle .rs-toggle-presentation {
  height: 20px;
}
.input--record-filter .rs-toggle .rs-toggle-presentation .rs-toggle-inner {
  line-height: 20px;
  font-size: 10px ;
}
.input--record-filter .rs-toggle .rs-toggle-presentation:after {
  top: 2px;
  width: 16px;
  height: 16px;
}
.input--record-filter .rs-toggle.rs-toggle-checked .rs-toggle-presentation:after {
  margin-left: -19px;
}
.input--record-filter .bw-select {
  font-size: 11px;
}
.input--record-filter .bw-select .ant-select-selector {
  height: 28px;
}
.input--record-filter .bw-select .ant-select-selection-item {
  line-height: 26px;
}
.input--record-filter .bw-select .ant-select-arrow {
  font-size: 10px;
}
.input--record-filter .ant-input,
.input--record-filter .ant-picker {
  font-size: 11px;
  height: 28px;
}
.input--record-filter .avatar-and-label img {
  height: 1rem;
}
.input--record-filter .filter-item {
  display: flex;
  flex-direction: row;
}
.input--record-filter .filter-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.input--record-filter .filter-item > div {
  display: flex;
  align-items: center;
}
.input--record-filter .filter-item > div:not(:last-child) {
  margin-right: 0.5rem;
}
.input--record-filter .filter-item .name-col {
  min-width: 6rem;
}
.input--record-filter .filter-item .name-col .bw-select.normal-width {
  width: 6rem;
}
.input--record-filter .filter-item .name-col .bw-select.wider-wdith {
  width: 100%;
}
.input--record-filter .filter-item .name-col .bw-select,
.input--record-filter .filter-item .name-col .bw-select__control,
.input--record-filter .filter-item .name-col bw-select__indicators {
  height: 30px;
  min-height: 30px;
}
.input--record-filter .filter-item .op-input-col {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 30px;
  box-sizing: border-box;
}
.input--record-filter .filter-item .op-input-col .input-col {
  width: 11.125rem;
}
.input--record-filter .filter-item .op-input-col .bw-select,
.input--record-filter .filter-item .op-input-col .bw-select__control,
.input--record-filter .filter-item .op-input-col bw-select__indicators {
  border: none;
  box-shadow: none;
  height: 28px;
  min-height: 28px;
  outline: 0;
}
.input--record-filter .filter-item .op-input-col .bw-select .indicator {
  padding: 4px 5px;
}
.input--record-filter .filter-item .op-input-col .ant-select-auto-complete.ant-select .ant-input,
.input--record-filter .filter-item .op-input-col .ant-select-auto-complete.ant-select input {
  vertical-align: top;
  border: none;
  height: 26px;
}
.input--record-filter .filter-item .op-input-col .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: 26px;
}
.input--record-filter .filter-item .input-col {
  min-width: 6rem;
}
.input--record-filter .filter-item:hover .button-col {
  display: flex;
}
.input--record-filter .ant-switch .anticon {
  font-size: 10px;
}
.input--record-filter .add-btn {
  width: 7.5rem;
}
.input--record-filter .bool-input-col {
  width: 252px;
}
.input--record-filter--dropdown .ant-select-dropdown-menu-item {
  font-size: 11px;
  line-height: 20px;
}
.input--record-filter--dropdown .avatar-and-label img {
  height: 1rem;
}
