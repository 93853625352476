.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 20px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #108ee9;
}
.logo {
  height: 64px;
  padding: 12px;
  text-align: center;
}
.logo .logo-text {
  color: #FFB044;
  font-size: 16px;
}
.logo-img {
  height: 40px;
}
.design-app {
  font-size: 11px;
  display: flex;
  flex-direction: column;
}
.design-app .ant-breadcrumb {
  font-size: 11px;
}
.design-app .ant-tabs-nav .ant-tabs-tab {
  padding: 10px 16px;
  font-size: 11px;
}
.design-app .admin--tabs.ant-tabs-card {
  height: 100%;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-content {
  margin-top: -18px;
}
.design-app .admin--tabs.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 10px;
  height: 100%;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container {
  height: 30px;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
  height: 32px;
  line-height: 30px;
  padding: 0;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .tab-name-box {
  position: relative;
  padding: 0 1rem;
}
.design-app .admin--tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.design-app .ant-card .ant-card-head-title {
  font-size: 14px;
  padding: 12px 0;
}
.design-app .ant-card .ant-card-body {
  padding: 12px 16px;
}
.design-app .app-header {
  background-color: #fff !important;
  padding-left: 0!important;
}
.design-app .sider-account-box {
  margin: 32px 0 12px 0!important;
}
.design-app .sider-account-box.ant-menu-dark {
  background: #404040;
}
.design-app .sider-account-box .ant-menu-sub {
  background: #333 !important;
  box-shadow: none!important;
}
.design-app .sider-account-box .ant-menu-submenu-title {
  padding: 0 12px 0 12px!important;
}
.design-app .sider-account-box .ant-menu-submenu-title .ant-avatar {
  background: #282828;
}
.design-app .sider-account-box .ant-menu-submenu-title .ant-avatar .anticon-user {
  margin-right: 0px;
  font-weight: 300!important;
  line-height: 30px!important;
}
.design-app .sider-account-box .name {
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  font-weight: 300;
  margin-left: 12px;
}
.design-app .trigger-box-div {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.design-app .ant-layout-sider-collapsed .sider-account-box.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 0 0 16px!important;
}
.design-app .ant-layout-sider-collapsed .sider-account-box.ant-menu-inline-collapsed .name {
  display: none!important;
}
