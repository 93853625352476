.studio-editor-area {
  flex-grow: 100;
  height: calc(100% - 1.75rem);
  background-color: white;
  position: relative;
}
.studio-editor-area .editor-main {
  width: 100%;
  height: calc(100% - 1.75rem);
  display: flex;
}
.studio-editor-area .status-panel {
  border-left: 0.5px solid #f0f0f0;
  height: 100%;
}
.studio-editor-area .status-panel .status-panel-toolbar {
  width: 100%;
  padding: 0.5rem;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.studio-editor-area .status-panel .status-panel-body {
  height: calc(100% - 30px);
  padding: 0 1rem;
}
.studio-editor-area .status-panel .status-panel-body > :not(:last-child) {
  margin-bottom: 1.5rem;
}
.studio-editor-area .status-panel .status-panel-body .result-panel {
  padding: 16px 0;
  height: calc(100% - 300px);
  max-height: 600px;
  overflow: auto;
}
.studio-editor-area .status-panel .status-panel-body .result-panel > :not(:last-child) {
  margin-bottom: 1.5rem;
}
.studio-editor-area .status-panel .status-panel-body .result-panel .result-section .result-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
}
.studio-editor-area .status-panel .status-panel-body .result-panel .result-section > * {
  padding: 4px 8px;
}
.studio-editor-area .editing-panel {
  height: 100%;
  position: relative;
}
.studio-editor-area .editing-panel .editor-mode {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.studio-editor-area .editing-panel .editor-mode.visible {
  display: block;
}
.studio-editor-area .editor-bar {
  height: 1.75rem;
  width: 100%;
  border-bottom: 0.5px solid #f0f0f0;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
}
.studio-editor-area .editor-bar .separator {
  width: 1px;
  background-color: #f0f0f0;
  margin: 8px 4px;
}
.studio-editor-area .editor-bar .left-group {
  height: 100%;
  display: flex;
}
.studio-editor-area .editor-bar .right-group {
  height: 100%;
  display: flex;
}
.studio-editor-area .editor-bar .right-group > :not(:last-child) {
  margin-right: 0.5rem;
}
