.shadow-edit-container {
  width: 274px;
  padding: 8px;
  user-select: none;
}
.shadow-edit-container > .angle-edit-container {
  border-bottom: none;
}
.shadow-edit-container .shadow-type-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.shadow-edit-container .shadow-type-container .options {
  width: 100%;
}
.shadow-edit-container .shadow-color-container {
  display: flex;
  gap: 8px;
}
