.admin--form {
  cursor: default;
  flex: 1;
  position: relative;
  margin: 0 0.5rem;
  padding: 0.5rem 0;
  min-height: 300px;
  align-self: stretch;
}
.admin--form .ant-card-head-title {
  padding: 14px 0;
}
.admin--form.editing {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.admin--form.editing .edit-btn-box {
  display: flex;
  opacity: 1;
}
.admin--form:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.admin--form:hover .edit-btn-box {
  display: flex;
  opacity: 1;
}
.admin--form.full-height {
  height: 100%;
}
.admin--form.full-height.ant-card .ant-card-body {
  height: 100%;
}
.admin--form .single-value {
  height: 100%;
  margin: 0 auto;
  max-width: 680px;
}
.admin--form .single-value .bordered-input {
  border: none;
}
.admin--form .single-value .editor {
  height: 100%;
  overflow: auto;
}
.admin--form .single-value .record-col-overflow {
  overflow: auto;
  height: calc(100% - 2rem);
  margin-top: 2rem;
  padding: 0 1rem;
}
.admin--form .edit-btn-box {
  position: absolute;
  display: none;
  top: 12px;
  right: 8px;
}
.admin--form .edit-btn-box .edit-button {
  color: #3E8ECD;
}
.admin--form .edit-btn-box .confirm-button {
  color: #00A854;
}
.admin--form .edit-btn-box .button:hover {
  background-color: #f5f5f5;
}
.admin--form .edit-btn-box .button {
  font-size: larger;
  padding: 8px;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin--form .edit-btn-box .button.disabled {
  opacity: 0.15;
  cursor: default;
}
.admin--form .form-item-horizontal:not(:last-child),
.admin--form .form-item-vertical:not(:last-child) {
  margin-bottom: 1rem;
}
.admin--form .form-item-horizontal {
  display: flex;
  flex-direction: row;
}
.admin--form .form-item-horizontal .label-box {
  width: 21%;
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
  padding-right: 16px;
}
.admin--form .form-item-horizontal .label-box label {
  margin-bottom: 0;
}
.admin--form .form-item-horizontal .value-box {
  width: 70%;
  display: flex;
}
.admin--form .form-item-horizontal .value-box .error-message-cot {
  margin-top: 6px;
  font-size: smaller;
  color: #f5222d;
}
.admin--form .form-item-vertical {
  display: flex;
  flex-direction: column;
}
.admin--form .form-item-vertical:first-child {
  margin-top: 1rem;
}
.admin--form .form-item-vertical > :not(:last-child) {
  margin-bottom: 0.5rem;
}
