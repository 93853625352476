.code-playground {
  height: 100%;
}
.code-playground .defs-code-editor {
  height: 60%;
}
.code-playground .eval-area {
  height: 40%;
  background-color: white;
  border-bottom: 1px solid #F0F0F0;
}
.code-playground .eval-area .tool-bar {
  padding: 0.5rem 1rem;
}
.code-playground .eval-area .eval-result {
  padding: 0.5rem 1rem;
}
