.title-icon {
  display: flex;
}
.style-with-options {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 54px 1fr;
}
.style-with-options:not(:last-child) {
  margin-bottom: 0.5rem;
}
.options {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 1px;
}
.options .option-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  cursor: pointer;
  background-color: #F0F3F5;
  color: #454545;
}
.options .option-box.active {
  background-color: #4297da;
  color: white;
}
.options .option-box.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.unit-select {
  width: 24px;
}
.unit-select .arco-select-view {
  padding: 0!important;
  font-size: 9px!important;
  text-align: center!important;
}
.unit-select .arco-select-view .arco-select-suffix {
  margin-left: 0;
}
.unit-select-dropdown {
  width: 32px;
}
.unit-option {
  font-size: 9px!important;
  padding: 0 4px!important;
  line-height: 24px!important;
}
.limited-width-tooltip {
  max-width: 300px;
}
