.login-loading {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
}
.login-page {
  background: url("./images/bg.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.login-page .form {
  width: 380px;
  padding: 2.5rem 4.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-page .form button {
  width: 100%;
}
.login-page .form p {
  color: #cccccc;
  text-align: center;
  margin-top: 16px;
}
.login-page .form p span:first-child {
  margin-right: 16px;
}
.login-page .form > :not(:last-child) {
  margin-bottom: 28px;
}
.login-page .form .login-box {
  height: 200px;
  display: flex;
  justify-content: center;
}
.login-page .form .mode-select {
  font-size: 14px;
  display: flex;
}
.login-page .form .mode-select .select-button {
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.login-page .form .mode-select .separator {
  margin: 0.5rem 1rem;
  opacity: 0.5;
}
.login-page .password-login-form {
  width: 220px;
}
.login-page .password-login-form input,
.login-page .password-login-form button {
  font-size: 14px;
}
.login-page .password-login-form > :not(:last-child) {
  margin-bottom: 2rem;
}
.login-page .qr-code-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-page .qr-code-box .qr-code-gen-loading {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page .qr-code-box .qr-code-tips {
  margin-top: 1rem;
}
.login-logo {
  text-align: center;
  line-height: 40px;
}
.login-logo img {
  width: 64px;
  margin-right: 8px;
}
.login-logo .title {
  vertical-align: text-bottom;
  font-size: 18px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
