.admin--record-history .history-item .ant-timeline-item-content {
  display: flex;
  flex-direction: column;
}
.admin--record-history .history-item .avatar img {
  border-radius: 50%;
  height: 1rem;
}
.admin--record-history .history-item .event {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
}
.admin--record-history .history-item .event .operator {
  display: inline-flex;
  align-items: center;
}
.admin--record-history .history-item .event .event-desc,
.admin--record-history .history-item .event .operator,
.admin--record-history .history-item .event .field-name {
  margin-right: 0.25rem;
}
.admin--record-history .history-item .event .field-name {
  font-weight: 900;
}
.admin--record-history .history-item .time {
  opacity: 0.7;
}
