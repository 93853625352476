.admin--color-content {
  height: 1.5rem;
  width: 5rem;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin--color-content:hover .color-value {
  visibility: visible;
}
.admin--color-content .color-value {
  visibility: hidden;
  font-size: 11px;
}
