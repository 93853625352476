.admin--id {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: start;
  word-break: keep-all;
}
.admin--id :not(:last-child) {
  margin-right: 0.5rem;
}
