.lc-excel-to-form {
  padding: 1rem 1.5rem;
}
.lc-excel-to-form .field-item {
  position: relative;
}
.lc-excel-to-form .field-item .item-label {
  margin-bottom: 0.25rem;
}
.lc-excel-to-form .field-item .item-label.item-require::before {
  content: "*";
  position: absolute;
  left: -8px;
  color: #f5222d;
}
