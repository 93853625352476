.test-icons {
  height: 100%;
}
.test-icons .top-tool-bar {
  height: 40px;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  width: 100%;
  justify-content: space-between;
}
.test-icons .top-tool-bar .colored-tag {
  cursor: pointer;
}
.test-icons .top-tool-bar .rs-input {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.test-icons .top-tool-bar .rs-input:focus {
  outline: none;
  box-shadow: none;
}
.test-icons .icon-list-wrapper {
  height: calc(100% - 40px);
  background-color: white;
  overflow: auto;
}
.test-icons .icon-list-wrapper .icon-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-left: 2rem;
}
.test-icons .icon-list-wrapper .icon-list > div {
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}
.test-icons .icon-list-wrapper .icon-list > div .icon {
  width: 8rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  line-height: 1;
}
.test-icons .icon-list-wrapper .icon-list > div .name {
  width: 8rem;
  font-size: 11px;
  text-align: center;
}
