.wxmp-keywordReply-container {
  padding: 0rem 1rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wxmp-keywordReply-container .top {
  display: flex;
  flex-direction: column;
  color: #000;
  padding-bottom: 2rem;
  border-bottom: 1px solid #E4E8EB;
}
.wxmp-keywordReply-container .top > span:first-child {
  font-size: 1.25rem;
}
.wxmp-keywordReply-container .top > span:last-child {
  padding-top: 0.5rem;
  color: #aaa;
}
.wxmp-keywordReply-container .kp-table-operation {
  margin: 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
}
.wxmp-keywordReply-container .kp-table-container {
  height: calc(100% - 4rem);
}
.wxmp-keywordReply-container .details > div {
  display: flex;
}
.wxmp-keywordReply-container .details > div:not(:last-child) {
  margin-bottom: 2rem;
}
.wxmp-keywordReply-container .details > div label {
  color: #9A9A9A;
  float: left;
  margin-right: 2rem;
  width: 5em;
  white-space: nowrap;
}
.wxmp-keywordReply-container .details > div span {
  margin-right: 2.5rem;
  white-space: nowrap;
}
.wxmp-keywordReply-container .details > div span span {
  color: #9A9A9A;
}
.wxmp-keywordReply-container .details > div > div {
  flex: 1;
}
.wxmp-keywordReply-container .details > div > div > div {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #E4E8EB;
}
.wxmp-keywordReply-container .details > div > div > div .news {
  width: 20rem;
  height: auto;
  padding: 1.25rem 1rem 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: none;
}
.wxmp-keywordReply-container .details > div > div > div .news span:first-child {
  font-size: 1rem;
  margin-right: 0;
  height: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.wxmp-keywordReply-container .details > div > div > div .news img {
  width: 100%;
  height: auto;
  margin-top: 1.25rem;
}
.wxmp-keywordReply-container .details > div > div > div .news span:last-child {
  padding-top: 0.75rem;
  max-height: 4.45rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-right: 0;
  white-space: normal;
}
.wxmp-keywordReply-container .details > div > div > div > div {
  border: 1px solid #E4E8EB;
  width: 11.25rem;
  height: 7.5rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
