.operation-button-box {
  height: 100%;
  position: relative;
}
.operation-button-box .button-panel {
  position: absolute;
  right: 0;
  top: 36px;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
