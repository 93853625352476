.admin--color-palette {
  height: 100%;
  overflow: auto;
  padding: 1rem;
}
.admin--color-palette > :not(:last-child) {
  margin-bottom: 1rem;
}
.admin--color-palette .color-palette .derived-color:hover .color-name {
  display: block;
}
.admin--color-palette .color-palette .color-name {
  display: none;
}
.admin--color-palette .manipulation-demo {
  display: flex;
  flex-direction: column;
}
.admin--color-palette .manipulation-demo .controls {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.admin--color-palette .manipulation-demo .controls > div {
  display: flex;
}
.admin--color-palette .manipulation-demo .controls > div:not(:last-child) {
  margin-bottom: 0.5rem;
}
.admin--color-palette .manipulation-demo .controls > div > .label {
  min-width: 12rem;
  width: 12rem;
  padding-right: 1rem;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admin--color-palette .manipulation-demo .controls > div > input {
  width: 12rem;
  margin: 0 0.25rem;
}
.admin--color-palette .manipulation-demo .controls > div > .ant-radio-group {
  margin: 0 0.25rem;
}
.admin--color-palette .manipulation-demo .demo {
  display: flex;
  margin-bottom: 1rem;
}
.admin--color-palette .manipulation-demo .demo > .label {
  min-width: 12rem;
  width: 12rem;
  padding-right: 1rem;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admin--color-palette .manipulation-demo .demo .demo-value {
  display: flex;
  flex-wrap: wrap;
}
.admin--color-palette .manipulation-demo .demo .demo-value > div {
  height: 2rem;
  width: 10rem;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin--color-palette .manipulation-demo .demo .demo-value > button {
  width: 8rem;
  height: 2rem;
  margin: 0.25rem;
}
.admin--color-palette .manipulation-demo .demo .demo-value button {
  border: none;
  border-radius: 2px;
  outline: none;
}
