.spacing-container {
  display: grid;
  grid-template-columns: 36px 4px 36px 1fr 36px 4px 36px;
  grid-template-rows: 24px 4px 24px 24px 24px 4px 24px;
  user-select: none;
}
.spacing-container .margin-box {
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-template-rows: 24px minmax(16px, 1fr) 24px;
}
.spacing-container .padding-box {
  grid-area: 3 / 3 / span 3 / span 3;
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-template-rows: 24px minmax(16px, 1fr) 24px;
}
.spacing-container .value-label {
  display: flex;
  place-self: center;
  user-select: none;
  font-size: 10px;
  cursor: pointer;
  padding: 4px 8px;
}
.spacing-container .value-label.Top {
  grid-area: 1 / 2 / 2 / 3;
}
.spacing-container .value-label.Right {
  grid-area: 2 / 3 / 3 / 4;
}
.spacing-container .value-label.Bottom {
  grid-area: 3 / 2 / 4 / 3;
}
.spacing-container .value-label.Left {
  grid-area: 2 / 1 / 3 / 2;
}
.spacing-container .value-label.active {
  color: #4297da;
}
.spacing-container path:hover {
  fill: #DCDEE0;
}
.spacing-container .label {
  pointer-events: none;
  font-size: 8px;
  font-weight: bold;
  font-style: italic;
  padding: 0 4px;
  color: #8c8d8f;
}
.spacing-container .label.margin {
  grid-area: 1 / 1 / -1 / -1;
}
.spacing-container .label.padding {
  grid-area: 3 / 3 / span 3 / span 3;
}
.spacing-value-edit-container {
  width: 248px;
  display: grid;
  padding: 0.5rem;
}
.spacing-value-edit-container .value-input-container {
  display: flex;
  align-items: center;
}
.spacing-value-edit-container .value-input-container .icon-box {
  padding: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.spacing-value-edit-container .value-select-container {
  display: grid;
  grid-area: 2 / 1 / 3 / -1;
  grid-template-rows: 24px 24px;
  gap: 4px;
  margin-top: 0.5rem;
}
.spacing-value-edit-container .value-select-container.margin {
  grid-template-columns: 1.5fr repeat(4, 1fr);
}
.spacing-value-edit-container .value-select-container.padding {
  grid-template-columns: repeat(4, 1fr);
}
.spacing-value-edit-container .value-select-container .margin-auto {
  grid-row: span 2 / auto;
}
.spacing-value-edit-container .value-select-container .margin-auto,
.spacing-value-edit-container .value-select-container .option {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #F0F3F5;
  user-select: none;
}
.spacing-value-edit-container .value-select-container .margin-auto:hover,
.spacing-value-edit-container .value-select-container .option:hover {
  background-color: #DCDEE0;
}
.spacing-value-edit-container .reset-container {
  box-shadow: none;
  margin-top: 4px;
}
