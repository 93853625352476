.lc-export-form-to-excel .toolbar {
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem;
}
.lc-export-form-to-excel .job-list {
  padding: 0 1rem 1rem 1.25rem;
}
.lc-export-form-to-excel .job-list > :not(:last-child) {
  margin-bottom: 1rem;
}
.lc-export-form-to-excel .job-list .job-summary {
  display: flex;
  padding: 0.25rem 0;
}
.lc-export-form-to-excel .job-list .job-summary .job-title {
  overflow: hidden;
  margin-right: 1rem;
  flex-grow: 1000;
  min-width: 0;
}
.lc-export-form-to-excel .job-list .job-summary .job-info {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  min-width: 76px;
}
.lc-export-form-to-excel .job-list .job-summary .job-info > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-export-form-to-excel .job-list .job-summary .job-info .record-count {
  opacity: 0.6;
}
