.angle-edit-container {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f3f5;
}
.angle-edit-container .angle-edit {
  width: 100%;
  margin-left: 16px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.angle-edit-container .angle-edit > div:first-child {
  display: flex;
  align-items: center;
  color: #757575;
}
.angle-edit-container .angle-edit > div:first-child svg:not(:last-child) {
  margin-right: 10px;
}
.angle-edit-container .angle-edit .unit-select {
  width: 32px;
}
