.admin--pagination {
  display: flex;
  text-align: right;
  align-items: center;
}
.admin--pagination .pagination-size {
  width: 3.6rem;
  margin-left: 0.5rem;
}
.admin--pagination .ant-pagination-jump-prev,
.admin--pagination .ant-pagination-jump-next {
  width: 24px;
  min-width: 24px;
}
.admin--pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.admin--pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  font-size: 10px;
}
