.custom-date-range-picker {
  display: flex;
}
.custom-date-range-picker .rs-picker-date {
  min-width: 6rem !important;
  width: 8rem;
}
.custom-date-range-picker .rs-picker-date .rs-picker-toggle-active {
  border-color: #e5e5ea;
}
.custom-date-range-picker .rs-picker-date .rs-picker-toggle {
  height: 30px;
  font-size: 11px !important;
  min-height: 30px !important;
  padding-right: 16px !important;
  border-radius: 4px;
  padding-top: 6px;
}
.custom-date-range-picker .rs-picker-date .rs-picker-toggle .rs-picker-toggle-textbox {
  padding-right: 4px;
}
.custom-date-range-picker .range-connector {
  display: flex;
  border: 1px solid #e5e5ea;
  border-left: none;
  border-right: none;
  min-width: 0.75rem;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
}
.custom-date-range-picker .left-picker .rs-picker-toggle:not(:hover):not(:active):not(:focus) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-date-range-picker .rs-picker-toggle-caret {
  display: none;
}
.custom-date-range-picker .rs-picker-toggle-clean {
  right: 8px !important;
  font-size: 8px;
}
.custom-date-range-picker .right-picker .rs-picker-toggle:not(:hover):not(:active):not(:focus) {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
