.admin--card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.admin--card .card-body {
  flex-grow: 1;
}
.admin--card .card-body :last-child {
  margin-bottom: 0.5rem;
}
.admin--card .card-footer {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  opacity: 0.4;
}
.admin--card .card-footer :not(:last-child) {
  margin-bottom: 0.5rem;
}
