.react-flow__attribution {
  display: none;
}
.language-vis .react-flow__edge-path {
  stroke: #32CD32;
  stroke-width: 3;
}
.language-vis .react-flow__edge-path.string {
  stroke: #FF8C00;
}
.language-vis .react-flow__edge-path.bool {
  stroke: Turquoise;
}
.language-vis .react-flow__edge-path.record {
  stroke: #3498DB;
}
.language-vis .expr-node.node-literal,
.language-vis .expr-node.node-ref {
  color: #32CD32;
}
.language-vis .expr-node.node-literal.string,
.language-vis .expr-node.node-ref.string {
  color: #FF8C00;
}
.language-vis .expr-node.node-literal.bool,
.language-vis .expr-node.node-ref.bool {
  color: Turquoise;
}
.language-vis .expr-node.node-literal.record,
.language-vis .expr-node.node-ref.record {
  color: #3498DB;
}
.language-vis .expr-node .react-flow__handle-top {
  background-color: #32CD32;
}
.language-vis .expr-node .react-flow__handle-top.string,
.language-vis .expr-node .react-flow__handle-top.string::before {
  background-color: #FF8C00;
}
.language-vis .expr-node .react-flow__handle-top.bool,
.language-vis .expr-node .react-flow__handle-top.bool::before {
  background-color: Turquoise;
}
.language-vis .expr-node .react-flow__handle-top.record,
.language-vis .expr-node .react-flow__handle-top.record::before {
  background-color: #3498DB;
}
.language-vis .expr-node .react-flow__handle-top.collapsed {
  background: none;
  padding: 0;
  min-width: 0;
  color: #32CD32;
}
.language-vis .expr-node .react-flow__handle-top.collapsed::before {
  display: none;
}
.language-vis .expr-node .react-flow__handle-top.collapsed.string {
  color: #FF8C00;
}
.language-vis .expr-node .react-flow__handle-top.collapsed.bool {
  color: Turquoise;
}
.language-vis .expr-node .react-flow__handle-top.collapsed.record {
  color: #3498DB;
}
.language-vis .expr-node.node-if {
  background-color: #EBFFF5;
}
.language-vis .expr-node.node-ref {
  background-color: ghostwhite;
}
.language-vis {
  height: 100%;
  width: 100%;
  position: relative;
  color: #646566;
}
.language-vis .vis-toolbar {
  padding: 0.25rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 6px;
  background-color: white;
  z-index: 100;
  position: absolute;
  top: 16px;
  right: 16px;
}
.language-vis .vis-toolbar .bw-select {
  width: 6rem;
}
.language-vis .vis-toolbar .bw-select .bw-select__control {
  border: none;
  min-height: 28px;
}
.language-vis.layout-needed .react-flow__edge {
  visibility: hidden;
}
.language-vis.layout-needed .react-flow__node {
  visibility: hidden;
}
.language-vis .react-flow__node.selected .expr-node::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border: 1px dashed #47A9FF;
  pointer-events: none;
  border-radius: 9999px;
}
.language-vis .expr-node {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 9999px;
  overflow: hidden;
}
.language-vis .expr-node .react-flow__handle-bottom {
  display: none;
  bottom: -2px;
}
.language-vis .expr-node .react-flow__handle {
  border: none;
}
.language-vis .expr-node.node-output .node-content {
  padding: 0.25rem;
}
.language-vis .expr-node.node-output .react-flow__handle-top {
  aspect-ratio: 1;
  min-width: 18px;
}
.language-vis .expr-node.node-literal,
.language-vis .expr-node.node-ref {
  font-weight: bold;
  font-size: 11px;
}
.language-vis .expr-node.node-literal .label-box,
.language-vis .expr-node.node-ref .label-box {
  padding: 0 4px;
}
.language-vis .expr-node.node-unsupported {
  height: 1.5rem;
  aspect-ratio: 2;
  background-color: #FFDA47;
}
.language-vis .expr-node.node-unsupported .react-flow__handle {
  background-color: #FFDA47;
}
.language-vis .expr-node.node-unsupported .react-flow__handle-bottom {
  border: none;
  bottom: 0px;
}
.language-vis .expr-node .react-flow__handle-bottom {
  height: 2px;
  width: 4px;
  border-radius: 2px;
}
.language-vis .expr-node.node-literal .node-content,
.language-vis .expr-node.node-ref .node-content {
  padding: 0 0.5rem;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language-vis .expr-node.node-binop,
.language-vis .expr-node.node-unop {
  color: #8C8D8F;
}
.language-vis .expr-node .react-flow__handle-top {
  left: 0;
  top: 0;
  transform: translate(0);
  position: relative;
  height: 18px;
  min-width: 18px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px 3px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1em;
  width: initial;
  color: white;
}
.language-vis .expr-node .react-flow__handle-top::before {
  content: "";
  height: 0.3rem;
  width: 3px;
  background-color: #32CD32;
  position: absolute;
  top: -0.3rem;
}
.language-vis .expr-node .node-content {
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
}
.language-vis .expr-node .node-content > :not(:last-child) {
  margin-right: 0.5rem;
}
