.file-uploader {
  width: 100%;
}
.file-uploader .ant-upload.ant-upload-drag {
  height: 8rem;
}
.file-uploader .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-drag-icon,
.file-uploader .ant-upload.ant-upload-drag .ant-upload-drag-container .ant-upload-hint {
  margin: 1.5rem 0;
}
.file-uploader .file-list .file-list-item {
  margin-top: 0.5rem;
}
.file-uploader .file-list .file-list-item .file-name-col {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
  line-height: 22px;
}
.file-uploader .file-list .file-list-item .file-name-col:hover {
  cursor: pointer;
}
.file-uploader .file-list .file-list-item .file-name-col .paper-clip-icon {
  margin-top: 0.25rem;
  color: #3e8ecd;
}
.file-uploader .file-list .file-list-item .file-name-col .file-name {
  word-break: break-all;
  margin-left: 0.5rem;
  color: #3e8ecd;
}
.file-uploader .file-list .file-list-item .file-close-col {
  line-height: 22px;
}
.file-uploader .file-list .file-list-item .file-close-col .close-icon:hover {
  cursor: pointer;
}
