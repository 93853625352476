.sub_button-item {
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #e7e7eb;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.wxmp-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.wxmp-container .wxmp-breadcrumb-container {
  padding: 0.5rem 0.75rem 0;
}
.wxmp-container .wxmp-breadcrumb-container .breadcrumb {
  margin: 0 0 0.75rem 0.5rem;
}
.wxmp-container .wxmp-menuedit-container {
  height: 100%;
  overflow-y: scroll;
  flex: 1;
  margin: 0 1rem;
  padding: 1.5rem 2rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.wxmp-container .wxmp-menuedit-container .menu-edit {
  width: 100%;
  margin-top: 1rem;
  display: flex;
}
.wxmp-container .wxmp-menuedit-container .menu-edit > div:first-child {
  width: 18.875rem;
  flex-shrink: 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit > div:first-child > button {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile {
  width: 100%;
  height: 36.25rem;
  border: 1px solid #aaa;
  position: relative;
  display: flex;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile > div:first-child {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile > div:last-child {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  display: flex;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .button-item {
  height: 100%;
  line-height: 48px;
  text-align: center;
  border: 1px solid #e7e7eb;
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .button-item.no-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3e8ecd !important;
  border-color: #3e8ecd !important;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .sub_button-item {
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #e7e7eb;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .button-item:hover,
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .sub_button-item:hover {
  color: #000;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .mobile .popoverStyle div {
  padding: 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .info {
  width: 100%;
  margin-top: 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .info span:last-child {
  color: #aaa;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page {
  max-height: 50rem;
  max-width: 63.25rem;
  background-color: #f4f5f9;
  flex: 1;
  margin-left: 1.5rem;
  padding: 0 1.25rem 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .top {
  height: 2.5rem;
  padding: 0.5rem 0;
  border-bottom: 0.5px solid #ccc;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .top > span:last-child {
  float: right;
  cursor: pointer;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .edit-info {
  margin-top: 0.625rem;
  color: #aaa;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .name {
  height: 3.75rem;
  margin-top: 2rem;
  display: flex;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .name > label {
  width: 4.5rem;
  height: 1.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .name .error {
  display: flex;
  flex-direction: column;
  color: #aaa;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .name .error > span:first-child {
  color: #f00;
  margin: 0.25rem 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .radio {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .radio > label {
  width: 4.5rem;
  height: 1.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .radio div {
  display: flex;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .content-footer {
  margin-top: 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .content-footer button {
  margin-right: 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .edit-page .content-footer label {
  margin-right: 1rem;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .no-currentButton {
  flex: 1;
  height: 36.25rem;
  max-width: 63.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking {
  height: 20rem;
  width: 100%;
  max-width: 30rem;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon {
  position: absolute;
  font-size: 100px;
  cursor: pointer;
  color: #3e8ecd;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon.icon-disabled {
  cursor: not-allowed;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon-up {
  top: 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon-down {
  bottom: 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon-left {
  left: 0;
}
.wxmp-container .wxmp-menuedit-container .menu-edit .ranking .anticon-right {
  right: 0;
}
.wxmp-container .wxmp-menuedit-container .publish {
  width: 100%;
  max-width: 63.25rem;
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
}
.modal-content {
  width: 100%;
  height: 16rem;
  padding: 4.25rem 2.75rem 6.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content .alarm {
  width: 3rem;
  height: 3rem;
  font-size: 2.5rem;
  margin-right: 0.625rem;
  color: #f00;
}
