@import '@radix-ui/colors/blackA.css';
.pdf-password-container {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 6px;
}
.pdf-password-container .password-input {
  all: unset;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 0 0 1px var(--blackA9);
  padding: 0 10px;
  height: 35px;
  line-height: 1;
}
.pdf-password-container .password-input:hover {
  box-shadow: 0 0 0 1px black;
}
.pdf-password-container .password-input:focus {
  box-shadow: 0 0 0 2px black;
}
.pdf-password-container .password-input::selection {
  background-color: var(--blackA9);
  color: white;
}
