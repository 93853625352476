.admin--color-input {
  display: flex;
}
.admin--color-input:hover .remove-icon {
  display: flex;
}
.admin--color-input .remove-icon {
  padding: 0 0.25rem;
  opacity: 0.35;
  cursor: pointer;
  line-height: 10px;
  display: none;
  align-items: center;
  font-size: 13px;
}
