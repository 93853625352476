.sandbox-indicator {
  background-color: #FFE270;
  padding: 0.25rem;
  position: sticky;
  top: 0px;
  text-align: center;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
  writing-mode: vertical-lr;
  letter-spacing: 0.5rem;
}
