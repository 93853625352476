.wx-attachment-container {
  width: 100%;
}
.wx-attachment-container > .wx-attachment {
  padding: 0rem 0.5rem 1rem;
}
.wx-attachment-container > .wx-attachment > .wx-text {
  white-space: pre-line;
  word-break: break-word;
}
.wx-attachment-container > .wx-attachment > img {
  max-width: 9rem;
  cursor: pointer;
}
.wx-attachment-container > .wx-attachment > .video {
  max-width: 16rem;
}
.wx-attachment-container > .wx-attachment > .link > div {
  display: flex;
  margin-bottom: 0.5rem;
}
.wx-attachment-container > .wx-attachment > .link > div > span {
  width: 2.5rem;
  flex-shrink: 0;
}
.wx-attachment-container > .wx-attachment > .link > div > a,
.wx-attachment-container > .wx-attachment > .link > div > div {
  word-break: break-word;
}
.wx-attachment-container > .wx-attachment > .link > div > img {
  max-width: 7rem;
  cursor: pointer;
}
.wx-attachment-container > .wx-attachment:not(:last-child) {
  border-bottom: 1px solid #f0f3f5;
  margin-bottom: 1rem;
}
