.draggable-input.edit-text-input {
  width: 100%;
}
.draggable-input.edit-text-input .drag-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.draggable-input.edit-text-input .draggable-input-item .ant-input {
  border-color: #dcdcdc;
}
.draggable-input.edit-text-input .draggable-input-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.draggable-input.edit-text-input textarea {
  box-sizing: border-box;
}
