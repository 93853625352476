.lc-test-dnd {
  height: 100%;
  overflow: auto;
  background-color: #fff;
  display: flex;
  padding: 1rem 4rem;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}
.lc-test-item {
  color: var(--gray12);
  padding: 0.5rem 0.75rem;
}
