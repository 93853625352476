.borders-container .radius-container .radius-edit-all {
  display: flex;
  align-items: center;
  gap: 8px;
}
.borders-container .radius-container .radius-edit-all .corner-select-icons {
  display: flex;
  align-items: center;
}
.borders-container .radius-container .radius-edit-individual {
  display: flex;
  font-size: 16px;
  flex-wrap: wrap;
  margin-left: 4rem;
  margin-top: 0.5rem;
}
.borders-container .radius-container .radius-edit-individual .radius-single {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.borders-container .radius-container .radius-edit-individual .radius-single.borderTopLeftRadius,
.borders-container .radius-container .radius-edit-individual .radius-single.borderBottomLeftRadius {
  margin-right: 1rem;
}
.borders-container .radius-container .radius-edit-individual .radius-single > div {
  margin-left: 0.25rem;
}
.borders-container .borders-edit-container {
  margin-top: 0.5rem;
}
.borders-container .borders-edit-container .borders-edit-area {
  margin-top: 0.25rem;
  display: flex;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons {
  align-items: center;
  display: grid;
  gap: 8px;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border {
  padding: 0.25rem;
  display: flex;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.selected {
  background-color: #8c8d8f;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.active {
  background-color: #4297da;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.selected,
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.active {
  color: #f0f3f5;
  border-radius: 2px;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.borderTop {
  grid-column-start: 2;
  grid-row-start: 1;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.borderLeft {
  grid-column-start: 1;
  grid-row-start: 2;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.borderAll {
  grid-column-start: 2;
  grid-row-start: 2;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.borderRight {
  grid-column-start: 3;
  grid-row-start: 2;
}
.borders-container .borders-edit-container .borders-edit-area .borders-select-icons .border.borderBottom {
  grid-column-start: 2;
  grid-row-start: 3;
}
.borders-container .borders-edit-container .borders-edit-area .border-input {
  width: 100%;
  margin-left: 1rem;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .borderstyle-options {
  grid-template-columns: 38px 1fr;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-width {
  display: flex;
  align-items: center;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-width > div:first-child {
  margin-right: 0.75rem;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-color-container {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-color-container > div:first-child {
  margin-right: 14px;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-color-container .arco-input-inner-wrapper {
  padding-left: 0!important;
}
.borders-container .borders-edit-container .borders-edit-area .border-input .border-color-container .border-color {
  width: 24px;
  height: 24px;
}
.borders-color-picker-popover .sketch-picker {
  width: 240px !important;
}
