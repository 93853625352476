.backgrounds-container {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 54px auto auto;
}
.backgrounds-container .images-container {
  grid-column: 1 / -1;
  border-bottom: 1px solid #f0f3f5;
  padding-bottom: 8px;
}
.backgrounds-container .images-container .images-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backgrounds-container .images-container .images-header .icon-add {
  font-size: 16px;
  cursor: pointer;
  display: flex;
}
.backgrounds-container .images-container .images-header .icon-add.hide {
  display: none;
}
.backgrounds-container .images-container .images-list {
  padding: 4px;
  background-color: #f0f3f5;
  margin-top: 4px;
}
.backgrounds-container .images-container .images-list .image-item {
  padding: 6px 3rem 6px 8px;
  display: flex;
  align-items: center;
  position: relative;
}
.backgrounds-container .images-container .images-list .image-item:not(:last-child) {
  border-bottom: 1px solid #c8cacc;
}
.backgrounds-container .images-container .images-list .image-item:hover .actions {
  display: flex;
}
.backgrounds-container .images-container .images-list .image-item:hover .actions.hide {
  display: none;
}
.backgrounds-container .images-container .images-list .image-item .actions {
  display: none;
  align-items: center;
  font-size: 14px;
  color: #8c8d8f;
  position: absolute;
  right: 4px;
}
.backgrounds-container .images-container .images-list .image-item .actions > div {
  display: flex;
}
.backgrounds-container .images-container .images-list .image-item .actions > div:first-child {
  margin-right: 8px;
}
.backgrounds-container .images-container .images-list .image-item .actions svg:hover {
  fill: #27282d;
}
.backgrounds-container .images-container .images-list .image-item .gradient-display {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.backgrounds-container .color-container,
.backgrounds-container .clip-container {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-column: 1 / -1;
  grid-template-columns: 54px 1fr;
}
.backgrounds-container .color-container .arco-input-inner-wrapper {
  padding-left: 0!important;
}
.backgrounds-container .color-container .bg-color {
  width: 24px;
  height: 24px;
  background-size: cover;
}
.image-edit-area {
  width: 248px;
  padding: 8px;
}
.image-edit-area > .style-with-options {
  border-bottom: 1px solid #f0f3f5;
  padding-bottom: 8px;
}
.image-edit-area .style-with-options {
  grid-template-columns: 42px 1fr;
}
.image-edit-area .style-with-options .option-box {
  font-size: 16px;
}
.image-edit-area .image-container .image-info {
  display: flex;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f0f3f5;
}
.image-edit-area .image-container .image-info .image-info-container {
  margin-left: 16px;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box {
  display: flex;
  margin-bottom: 8px;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box .image-display {
  width: 64px;
  height: 64px;
  padding: 4px;
  flex-shrink: 0;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 8px;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box .size {
  font-size: 10px;
  color: #8c8d8f;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box .half-size {
  display: flex;
}
.image-edit-area .image-container .image-info .image-info-container .image-info-box .half-size > label {
  margin-right: 4px;
}
.image-edit-area .image-container .image-size {
  padding: 0.5rem 0;
  display: flex;
  border-bottom: 1px solid #f0f3f5;
  justify-content: space-between;
  align-items: center;
}
.image-edit-area .image-container .image-size > div:first-child {
  place-self: flex-start;
}
.image-edit-area .image-container .image-size .size-edit-area {
  width: 78%;
}
.image-edit-area .image-container .image-size .size-edit-area .size-input {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.image-edit-area .image-container .image-size .size-edit-area .size-input .width,
.image-edit-area .image-container .image-size .size-edit-area .size-input .height {
  width: 49%;
}
.image-edit-area .image-container .image-size .size-edit-area .size-input .width .label,
.image-edit-area .image-container .image-size .size-edit-area .size-input .height .label {
  text-align: center;
  margin-top: 2px;
  font-size: 10px;
  color: #8c8d8f;
}
.image-edit-area .image-container .image-position {
  padding: 0.5rem 0;
  display: flex;
  border-bottom: 1px solid #f0f3f5;
}
.image-edit-area .image-container .image-position > div:first-child {
  place-self: flex-start;
  margin-right: 6px;
}
.image-edit-area .image-container .image-tile-attachment {
  padding: 0.5rem 0;
}
.image-edit-area .image-container .image-tile-attachment .text {
  font-size: 12px;
}
.image-edit-area .color-overlay-container {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 42px 1fr;
}
.image-edit-area .color-overlay-container .arco-input-inner-wrapper {
  padding-left: 0!important;
}
.image-edit-area .color-overlay-container .bg-color {
  width: 24px;
  height: 24px;
  background-size: cover;
}
.image-edit-area .radial-gradient-container .gradient-position {
  padding: 0.5rem 0;
  display: flex;
  border-bottom: 1px solid #f0f3f5;
}
.image-edit-area .radial-gradient-container .gradient-position > div:first-child {
  place-self: flex-start;
  margin-right: 6px;
}
.image-edit-area .radial-gradient-container .size-edit {
  padding: 8px 0;
  border-bottom: 1px solid #f0f3f5;
}
.image-edit-area .radial-gradient-container .size-edit .option-box {
  font-size: 12px;
}
.overlay-color-picker-popover .arco-popover-content {
  padding: 0;
}
