.admin--tabs.ant-tabs-card {
  height: 100%;
}
.admin--tabs.ant-tabs-card .ant-tabs-content {
  height: 100%;
  margin-top: -16px;
}
.admin--tabs.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 1rem 1rem 0.5rem;
  height: 100%;
}
.admin--tabs.ant-tabs-card > .ant-tabs-nav {
  border-color: #fff;
}
.admin--tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
  height: 2rem;
  line-height: 1.875rem;
  padding: 0;
}
.admin--tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .tab-name-box {
  position: relative;
  padding: 0 1rem;
}
.admin--tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.admin--tabs.ant-tabs-card .ant-tabs-tabpane.ant-tabs-tabpane-inactive {
  display: none;
}
