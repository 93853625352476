.studio-explorer {
  height: 100%;
  width: 100%;
  display: flex;
}
.studio-explorer .nav-bar {
  display: flex;
  flex-direction: column;
  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 100%;
}
.studio-explorer .nav-bar .explorer-nav {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
  user-select: none;
  border-bottom: solid 1px #e9e9e9;
  font-size: 11px;
  opacity: 0.7;
  cursor: pointer;
}
.studio-explorer .nav-bar .explorer-nav > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.studio-explorer .nav-bar .explorer-nav.active {
  background-color: #fff;
  border-bottom: none;
  cursor: default;
  opacity: 1;
}
.studio-explorer .explorer-body-wrapper {
  background-color: white;
  height: 100%;
  flex-grow: 100;
}
.studio-explorer .explorer-body-wrapper .explorer-body {
  height: 100%;
  width: 100%;
  display: none;
}
.studio-explorer .explorer-body-wrapper .explorer-body.active {
  display: flex;
  flex-direction: column;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab-nav {
  display: flex;
  height: 1.75rem;
  border-bottom: 0.5px solid #e9e9e9;
  border-left: 0.5px solid #e9e9e9;
  background-color: #FBFBFC;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab-nav > div {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab-nav > div:not(:last-child) {
  border-right: 0.5px solid #e9e9e9;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab-nav > div.active {
  opacity: 1;
  cursor: default;
  background-color: white;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab {
  height: calc(100% - 2rem);
  display: none;
}
.studio-explorer .explorer-body-wrapper .explorer-body .multitenancy-tab.active {
  display: flex;
}
.studio-explorer .explorer-body-wrapper .explorer-body .panel-content {
  overflow: hidden;
  margin-top: 0.25rem;
  height: 100%;
  width: 100%;
}
.studio-explorer .explorer-body-wrapper .explorer-body .panel-content:hover {
  overflow: auto;
}
