.admin-user-box {
  margin-left: 0.5rem;
}
.admin-user-box .user-avatar {
  height: 1.5rem;
  width: 1.5rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.admin-user-box .top-bar-user-panel {
  width: 16rem;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
}
.admin-user-box .top-bar-user-panel .user-info {
  flex: 1;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-around;
}
.admin-user-box .top-bar-user-panel .user-name {
  font-size: 14px;
  margin-bottom: 8px;
}
.admin-user-box .top-bar-user-panel .user-tags {
  display: flex;
  flex-wrap: wrap;
}
.admin-user-box .top-bar-user-panel .info-tag {
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.125rem 0.25rem;
  border-radius: 2px;
}
.admin-user-box .top-bar-user-panel .info-tag:not(:last-child) {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
.admin-user-box .top-bar-user-panel .user-operations {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.admin-user-box .top-bar-user-panel .user-operations .user-operation-button {
  padding: 0.5rem 1rem 0.5rem 1.25rem;
  letter-spacing: 0.125rem;
  cursor: pointer;
}
.admin-user-box .top-bar-user-panel .user-operations .user-operation-button:hover {
  background-color: rgba(0, 0, 0, 0.01);
}
.admin-user-box .top-bar-user-panel .user-operations .user-operation-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
