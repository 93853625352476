.drawer {
  position: relative;
  height: 100%;
}
.drawer .drawer-head {
  position: absolute;
  z-index: 1001;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer .drawer-head .left-buttons > :not(:last-child) {
  margin-right: 16px;
}
.drawer .drawer-body {
  padding: 24px;
  padding-top: 104px;
  overflow: auto;
}
.drawer .drawer-body.nohead {
  padding-top: 8px;
}
.drawer .drawer-edit-delete {
  padding: 0 24px 24px 24px;
}
