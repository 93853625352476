.attributes-editor .attributes-form .attribute-item .label {
  margin-bottom: 0.25rem;
  color: #424242;
}
.attributes-editor .attributes-form .attribute-item:not(:last-child) {
  margin-bottom: 1rem;
}
.attributes-editor .attributes-form .attribute-item .input.with-expression {
  padding-right: 22px;
  position: relative;
}
.attributes-editor .attributes-form .attribute-item .input.with-expression .lc-expression-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 13px;
  opacity: 0.5;
}
.attributes-editor .attributes-form .attribute-item .input.with-expression .lc-expression-icon.active {
  color: #1A73E9;
  opacity: 1;
}
.attributes-editor .attributes-form .attribute-item .input.with-expression .lc-expression-icon:hover {
  opacity: 1;
}
