.modal-cot.visible {
  visibility: visible;
}
.modal-cot.visible .modal-mask,
.modal-cot.visible .modal-wrap {
  transform: scale(1);
  transition: transform 2400ms ease-in;
}
.modal-cot.visible .modal-wrap {
  opacity: 1;
}
.modal-cot.hidden {
  visibility: hidden;
}
.modal-cot.hidden .modal-mask,
.modal-cot.hidden .modal-wrap {
  transform: scale(0);
  opacity: 1;
  transition: all 120ms ease-out;
}
.modal-cot .modal-mask,
.modal-cot .modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 500;
}
.modal-cot .modal-mask {
  background-color: rgba(39, 44, 54, 0.5);
}
.modal-cot .modal-wrap {
  display: flex;
}
.modal-cot .modal-wrap .modal-back {
  position: absolute;
}
.modal-cot .modal-wrap .modal-back {
  top: 3.25rem;
}
.modal-cot .modal-wrap .modal-close.no-modal-back {
  top: 3.25rem;
}
.modal-cot .modal-wrap .modal-close.has-modal-back {
  display: none;
  top: 9rem;
  opacity: 0.6;
}
.modal-cot .modal-wrap .side-collapsed .modal-close,
.modal-cot .modal-wrap .side-collapsed .modal-back {
  left: 0.75rem;
}
.modal-cot .modal-wrap .modal-close,
.modal-cot .modal-wrap .modal-back {
  position: absolute;
  left: 5rem;
  background: #f5f5f5;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
}
.modal-cot .modal-wrap .modal-close:hover,
.modal-cot .modal-wrap .modal-back:hover {
  cursor: pointer;
  color: #3e8ecd;
}
.modal-cot .modal-wrap .modal-close:hover {
  opacity: 1;
}
.modal-cot .modal-wrap .modal-close-icon,
.modal-cot .modal-wrap .modal-back-icon {
  transform: translateY(50%);
  font-size: 1.25rem;
}
.modal-cot .modal-wrap .modal-side {
  flex: 0 0 10rem;
  height: 100%;
}
.modal-cot .modal-wrap .modal-side.side-collapsed {
  flex: 0 0 4rem;
}
.modal-cot .modal-wrap .modal-side:hover .modal-close.has-modal-back {
  display: block;
}
.modal-cot .modal-wrap .modal-body {
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: #f0f2f5;
}
