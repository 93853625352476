.reset-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0.5px;
}
.reset-container .icon-box {
  padding: 0.5rem;
  border-bottom: 1px solid #f0f3f5;
  cursor: pointer;
}
.reset-container .icon-box:hover {
  background-color: #f7f7f7;
}
.reset-container .tip {
  padding: 0.5rem;
}
.resetableLabel {
  min-width: 54px;
  flex-shrink: 0;
}
.resetableLabel.active {
  cursor: pointer;
  color: #4297da;
}
