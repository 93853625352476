.lc-form-view {
  padding: 0.75rem;
  flex-grow: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.lc-form-view tbody .arco-table-tr {
  cursor: pointer;
}
.lc-form-view .arco-table {
  height: calc(100% - 40px);
}
.lc-form-view .operation-box {
  display: flex;
}
.lc-form-view .form-view-toolbar {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
.lc-form-view .form-view-toolbar .form-title .title-display {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
  margin-top: -4px;
  margin-bottom: -2px;
  margin-left: -4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.lc-form-view .form-view-toolbar .form-title .title-display:hover {
  background-color: var(--color-fill-2);
}
.lc-form-view .form-view-toolbar .form-title .title-display > :first-child {
  margin-right: 0.25rem;
  max-width: 10rem;
}
.lc-form-view .form-view-toolbar .form-title .title-display svg {
  opacity: 0.75;
}
.lc-form-view .form-view-toolbar .form-title .title-input {
  width: 8rem;
}
.lc-form-view .form-view-toolbar .form-title .title-input .arco-input {
  font-size: 14px;
}
.lc-form-view .form-view-toolbar .left,
.lc-form-view .form-view-toolbar .right {
  display: flex;
  align-items: center;
}
.lc-form-view .form-view-toolbar .left > :not(:last-child),
.lc-form-view .form-view-toolbar .right > :not(:last-child) {
  margin-right: 0.75rem;
}
.lc-form-view .form-view-toolbar .toggle-knowledge-btn svg {
  margin-right: 0.25rem;
}
.lc-form-view .form-view-toolbar .toggle-knowledge-btn.inactive {
  background-color: #F9F9FA;
}
.lc-form-view .form-view-toolbar .toggle-knowledge-btn.inactive:hover {
  background-color: #F0F0F0;
}
