.wxmp-view {
  height: 13rem;
  padding: 1rem 1.25rem;
  border: 1px solid #e7e7eb;
  background-color: white;
}
.wxmp-view p {
  color: #8d8d8d;
  padding-bottom: 0.625rem;
}
.wxmp-view > div {
  display: flex;
}
.wxmp-view > div label {
  width: 4.5rem;
  height: 1.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
