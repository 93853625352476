.admin--field-options-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.125rem 0 1rem 0;
}
.admin--field-options-input > :not(:last-child) {
  margin-bottom: 1rem;
}
.admin--field-options-input .rs-input {
  width: fit-content;
}
.admin--field-options-input .option-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.admin--field-options-input .option-group > :not(:last-child) {
  margin-bottom: 0.25rem;
}
.admin--field-options-input .option-group .label {
  opacity: 0.6;
}
.admin--field-options-input .color-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -0.5rem;
}
.admin--field-options-input .color-inputs .color-input {
  display: flex;
  width: 46%;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 2rem;
}
.admin--field-options-input .color-inputs .color-input .for-option {
  word-break: keep-all;
  margin-right: 0.5rem;
  opacity: 0.8;
}
