.admin--data-table .table-value {
  display: flex;
  flex-wrap: wrap;
}
.admin--data-table .table-value > :not(:last-child) {
  margin-right: 1rem;
}
.admin--data-table .operations {
  display: flex;
}
.admin--data-table .operations > :not(:last-child) {
  margin-right: 1rem;
}
.admin--data-table .table-footer {
  display: flex;
  padding: 0.5rem 1rem;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
}
.admin--data-table .table-footer .footer-note {
  opacity: 0.75;
}
