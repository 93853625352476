.typography-container > div:not(:last-child) {
  margin-bottom: 8px;
}
.typography-container .select-attr-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.typography-container .select-attr-container > div:first-child {
  width: 54px;
  flex-shrink: 0;
}
.typography-container .size,
.typography-container .color {
  align-items: center;
  display: grid;
  gap: 8px;
}
.typography-container .size {
  grid-template-columns: 54px 1fr 44px 1fr;
}
.typography-container .color {
  grid-template-columns: 54px 1fr;
}
.typography-container .style {
  display: flex;
}
.typography-container .style .italic-options-container,
.typography-container .style .decoration-options-container {
  text-align: center;
}
.typography-container .style .italic-options-container > div:last-child,
.typography-container .style .decoration-options-container > div:last-child {
  margin-top: 4px;
  font-size: 10px;
}
.typography-container .style .italic-options-container {
  margin-left: 34px;
  margin-right: 8px;
  width: 33.3%;
}
.typography-container .style .decoration-options-container {
  width: 66.6%;
}
.typography-container .options {
  font-size: 16px;
}
.typography-container .more-edit-toggle {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0.25rem 0;
  align-items: center;
  background-color: #F0F3F5;
  cursor: pointer;
  font-size: 10px;
}
.typography-container .more-edit-toggle:hover {
  background-color: #f7f7f7;
}
.typography-container .more-edit-toggle > svg {
  margin-right: 0.25rem;
}
.typography-container .more-edit-container .length-edit-container {
  display: flex;
  gap: 8px;
}
.typography-container .more-edit-container .length-edit-container .letterSpacing-box,
.typography-container .more-edit-container .length-edit-container .textIndent-box,
.typography-container .more-edit-container .length-edit-container .columnCount-box {
  font-size: 10px;
  text-align: center;
}
.typography-container .more-edit-container .length-edit-container .letterSpacing-box > div:last-child,
.typography-container .more-edit-container .length-edit-container .textIndent-box > div:last-child,
.typography-container .more-edit-container .length-edit-container .columnCount-box > div:last-child {
  margin-top: 4px;
}
.typography-container .more-edit-container .length-edit-container .columnCount-box .columnCount-input {
  display: flex;
  align-items: center;
  gap: 4px;
}
.typography-container .more-edit-container .length-edit-container .columnCount-box .columnCount-input > div {
  display: flex;
  padding: 2px;
  background-color: #f0f3f5;
}
.typography-container .more-edit-container .transform-direction-container {
  display: flex;
  margin: 8px 0;
  gap: 8px;
}
.typography-container .more-edit-container .transform-direction-container .transform-options-container,
.typography-container .more-edit-container .transform-direction-container .direction-options-container {
  text-align: center;
}
.typography-container .more-edit-container .transform-direction-container .transform-options-container > div:last-child,
.typography-container .more-edit-container .transform-direction-container .direction-options-container > div:last-child {
  margin-top: 4px;
  font-size: 10px;
}
.typography-container .more-edit-container .transform-direction-container .direction-options-container {
  width: 33.3%;
}
.typography-container .more-edit-container .transform-direction-container .transform-options-container {
  width: 66.6%;
}
.typography-container .more-edit-container .textShadow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 8px 0;
  border-top: 1px solid #f0f3f5;
}
.typography-container .more-edit-container .textShadow-header .icon-add {
  font-size: 16px;
  cursor: pointer;
  display: flex;
}
.typography-container .more-edit-container .textShadow-header .icon-add.hide {
  display: none;
}
.typography-container .more-edit-container .shadows-list {
  padding: 4px;
  background-color: #f0f3f5;
  margin-top: 4px;
}
.typography-container .more-edit-container .shadows-list .shadow-item {
  padding: 6px 3rem 6px 8px;
  display: flex;
  align-items: center;
  position: relative;
}
.typography-container .more-edit-container .shadows-list .shadow-item:not(:last-child) {
  border-bottom: 1px solid #c8cacc;
}
.typography-container .more-edit-container .shadows-list .shadow-item:hover .actions {
  display: flex;
}
.typography-container .more-edit-container .shadows-list .shadow-item:hover .actions.hide {
  display: none;
}
.typography-container .more-edit-container .shadows-list .shadow-item .actions {
  display: none;
  align-items: center;
  font-size: 14px;
  color: #8c8d8f;
  position: absolute;
  right: 4px;
}
.typography-container .more-edit-container .shadows-list .shadow-item .actions > div {
  display: flex;
}
.typography-container .more-edit-container .shadows-list .shadow-item .actions > div:first-child {
  margin-right: 8px;
}
.typography-container .more-edit-container .shadows-list .shadow-item .actions svg:hover {
  fill: #27282d;
}
.typography-container .more-edit-container .shadows-list .shadow-item .shadow-color-display {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.color-display {
  width: 24px;
  height: 24px;
  background-size: cover;
}
.attr-slider-label {
  width: 54px;
  flex-shrink: 0;
}
.attr-slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.attr-slider-container .attr-slider-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.column-properties-container {
  width: 274px;
  padding: 0.5rem;
}
.column-properties-container .title {
  margin-bottom: 8px;
  font-weight: bold;
}
.column-properties-container .divider-settings-container {
  margin: 6px 0px;
  padding: 4px 0px;
  border-top: 1px solid #f0f3f5;
  border-bottom: 1px solid #f0f3f5;
}
.column-properties-container .divider-settings-container .columnRule-color-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}
.column-properties-container .column-child-container .column-span-container {
  display: flex;
  gap: 8px;
}
