.lc-image-select .thumbnail-box {
  margin-top: 0.5rem;
  display: flex;
}
.lc-image-select .thumbnail-box .thumbnail {
  max-width: 100%;
  max-height: 80px;
}
.lc-image-select .size-select {
  margin-top: 0.5rem;
}
