.test-client {
  display: flex;
}
.test-client .side-bar {
  width: 240px;
}
.test-client .main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.test-client .main > * {
  margin-bottom: 1rem;
}
