.admin-page {
  width: 100%;
  height: 100%;
  min-width: 400px;
}
.admin-page .breadcrumb {
  margin: 4px 0;
}
.admin-page .admin-page-head {
  display: flex;
  padding: 8px 24px 8px 16px;
  justify-content: space-between;
  align-items: center;
}
.admin-page .admin-page-container {
  height: calc(100% - 42px);
  overflow: auto;
}
