.lc-expression-input .cm-editor {
  padding: 0.25rem 0.5rem;
}
.lc-expression-input .tool-panel {
  padding: 1rem;
}
.lc-expression-input-modal .arco-modal-content {
  padding: 0rem;
}
.lc-expression-input-modal.arco-modal {
  width: 70vw;
}
.lc-expression-input-modal .lc-expression-input {
  height: 68vh;
  overflow: auto;
}
