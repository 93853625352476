.article-editor,
.article-editor-main {
  height: 100%;
}
.article-editor {
  display: flex;
  flex-direction: column;
}
.article-editor .editor-toolbar {
  border-bottom: 1px solid #e9e9e9;
}
.article-editor .editor {
  flex: 1;
}
.article-editor.is-dropzone-hover {
  border-color: #1aaa55;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px rgba(26, 170, 85, 0.4);
}
.article-editor .DraftEditor-root {
  padding: 0.5rem;
  background-color: white;
  min-height: 100%;
}
