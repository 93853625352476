.lc-msg-select > :not(:last-child) {
  margin-bottom: 0.25rem;
}
.lc-msg-select .add-logic-link,
.lc-msg-select .edit-logic-link,
.lc-msg-select .not-a-link {
  padding: 0 0.25rem;
  font-size: 11px;
  opacity: 0.6;
}
.lc-msg-select .add-logic-link,
.lc-msg-select .edit-logic-link {
  cursor: pointer;
  text-decoration: underline;
}
.lc-msg-update-input {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.lc-msg-update-input .caption {
  padding: 0.5rem 1rem;
}
.lc-msg-update-input .caption pre {
  display: inline;
  margin: 0 0.5rem 0 0.25rem;
}
.lc-msg-update-input .caption .cm-editor {
  padding: 0.125rem 0.25rem;
}
.lc-msg-update-input .expr-editor {
  flex-grow: 100;
}
.lc-msg-update-input .cm-editor {
  padding: 0.5rem 1rem;
}
.lc-msg-update-input-modal .arco-modal-content {
  padding: 0rem;
}
.lc-msg-update-input-modal.arco-modal {
  width: 70vw;
}
.lc-msg-update-input-modal .lc-msg-update-input {
  height: 68vh;
  overflow: auto;
}
