.studio-styles-editor {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}
.studio-styles-editor .code-mirror-editor {
  height: 100%;
  flex-grow: 100;
  width: 80%;
}
.studio-styles-editor .code-mirror-editor .ͼ1 .cm-scroller {
  padding-bottom: 32rem;
}
