.studio-top-bar {
  height: 100%;
  padding: 0 0.25rem 0 0.375rem;
  z-index: 200;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.studio-top-bar .item-group {
  display: flex;
}
.studio-top-bar .admin-user-box {
  margin-left: 0;
}
.studio-top-bar .operation-button-box {
  height: 100%;
  position: relative;
}
.studio-top-bar .top-bar-button {
  padding: 0 0.5rem 0;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 15px;
  color: #646566;
}
.studio-top-bar .top-bar-button:hover {
  background-color: #F0F3F5;
}
.studio-top-bar .logo-and-name {
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  margin-left: 0.25rem;
  font-weight: 500;
}
.studio-top-bar .logo-and-name .studio-logo {
  height: 1rem;
  border-radius: 4px;
  background-position: center;
  background-size: contain;
}
